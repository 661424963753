<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-250 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '10%',
      }"
    >
      <!-- <span class="mask bg-gradient-info opacity-6"></span> -->
      <!-- <span class="mask bg-gradient-info opacity-5"></span> -->
      <span
        class="mask opacity-5"
        style="background-color: rgb(44, 222, 44)"
      ></span>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 30%">
          <h3 style="color: rgb(255, 255, 255); text-align: center">{{ $t("Optimizations Library") }}</h3>
          <h5 style="color: rgb(255, 255, 255); text-align: center">{{ line.name }}</h5>
        </div>
      </div>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="row text-center">
          <div
            class="col p-3"
            style="min-width: 100px; height: 150px"
          >
            <button
              class="w-60px h-100 mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="width: 200px; height: 100px; background-color: rgb(231, 245, 221); font-size: 0.9rem"
              @click="routeToNewOptimization"
            >
              {{ $t("New Optimization") }}
            </button>
          </div>
        </div>
        <div class="row">
          <OptimizationList :line-id="lineId"> </OptimizationList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OptimizationList from "./components/OptimizationList.vue";
import setTooltip from "@/assets/js/tooltip.js";
import logo from "@/assets/img/logo-ct-white.png";
import { useGetLine } from "@/views/composables.js";

export default {
  name: "OptimizationsLibrary",
  components: {
    OptimizationList,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    return { line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        logo,
      };
    }
  },
  computed: {
    isFixedLineLayout() {
      return this.line.pistols_layout == "f" || this.line.pistols_layout == "fixedmixed";
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },

  methods: {
    async getData () {
      await this.getLine(this.lineId);
    },
    routeToNewOptimization() {
      if (this.isFixedLineLayout) {
        this.$router.push({ name: "FixedLineOptimizationDetail", params: { 
          lineId: this.lineId,
          optimizationTypeProp: undefined,
          flightpathIdProp: undefined,
        } });
      } else {
        this.$router.push({ name: "OptimizationDetail", params: {
          lineId: this.lineId,
          optimizationTypeProp: undefined,
          flightpathIdProp: undefined,
        } });
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
       "Optimizations Library":"Optimizations Library",
       "New Optimization":"New Optimization",
       "Line Name":"Line Name",
       "Create Optimization":"Create Optimization",
    },
    "DE": {
        "Optimizations Library":"Optimierungsliste",
         "New Optimization":"Neue Optimierung",
         "Line Name":"Name der Linie",
         "Create Optimization":"Optimierung Erstellen",
    },
    "ES": {
        "Optimizations Library":"Lista de Optimizaciones",
        "New Optimization":"Nueva Optimización",
        "Line Name":"Nombre de la línea",
        "Create Optimization":"Crear Optimización",
    },
    "FR": {
        "Optimizations Library":"Liste des Optimisations",
        "New Optimization":"Nouvelle Optimisation",
        "Line Name":"Nom de la Ligne",
        "Create Optimization":"Créer l'Optimisation",
    },
    "IT": {
        "Optimizations Library":"Elenco delle Ottimizzazioni",
        "New Optimization":"Nuova Ottimizzazione",
        "Line Name":"Nome della Linea",
        "Create Optimization":"Creare l'Ottimizzazione",
    },
    "PL": {
        "Optimizations Library":"Lista Optymalizacji",
        "New Optimization":"Nowa optymalizacja",
        "Line Name":"Nazwa Linii",
        "Create Optimization":"Tworzenie Optymalizacji",
    },
    "TR": {
        "Optimizations Library":"Optimizasyonlar Listesi",
        "New Optimization":"Yeni Optimizasyon",
        "Line Name":"Hat Adı",
        "Create Optimization":"Optimizasyon Oluşturun",
    },
    "ZH": {
        "Optimizations Library":"优化列表",
        "New Optimization":"新优化",
        "Line Name":"线路名称",
        "Create Optimization":"创建优化",
    }
  }
</i18n>
