<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 text-start mt-3 mb-0">
            <button
              class="btn btn-outline-secondary btn-sm px-3 mt-1 mb-3"
              @click="$router.push({ name: 'BluePrint' })"
            >
              <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
              Go back to line list
            </button>
          </div>
          <div
            class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 d-flex flex-column card card-body blur shadow-blur"
          >
            <div class="card-header text-center">
              <h4 class="font-weight-bolder">Line Detail</h4>
              <h4 class="mt-2">{{ line.name }}</h4>
            </div>
            <div
              class="card-body mb-0 pb-3"
              style="padding-top: 0.5rem"
            >
              <div class="row">
                <h5 class="mt-2">Line Layout</h5>
                <div class="d-flex justify-content-center">
                  <canvas
                    id="lineLayoutCanvas"
                    class="col-12 mt-0 mb-4"
                    width="700"
                    height="300"
                    :style="`max-width: 1280px; ${line.id !== null ? '' : 'display: none;'}`"
                  >
                  </canvas>
                </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-center">
                  <div
                    class="table-responsive"
                    style="max-width: 1280px; width: 90%"
                  >
                    <table class="table table-sm text-center">
                      <thead>
                        <tr>
                          <th>Gun</th>
                          <th>Gun Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(gun, gunIndex) in gun_names_form"
                          :key="gunIndex"
                        >
                          <td>
                            {{ gunIndex + 1 }}
                          </td>
                          <td>
                            <input
                              v-model="gun.name"
                              type="text"
                              class="form-control form-control-sm"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center mt-4">
                <button
                  type="button"
                  class="mt-4 mb-2 text-center btn"
                  :class="gunsNameFormUpdated ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                  style="width: 50%"
                  @click="saveNewNames()"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { nextTick } from "vue";

import {
  useGetLine,
} from "@/views/composables.js";

export default {
  name: "LineDetailBluePrint",
  components: {
    FontAwesomeIcon,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    return { line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      gun_names_form: null,
      gun_names_loaded: null,
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
    };
  },
  computed: {
    totalLineGuns() {
      return this.line.total_pistols.reduce((a, b) => a + b, 0);
    },
    gunsNameFormUpdated() {
      return JSON.stringify(this.gun_names_form) !== JSON.stringify(this.gun_names_loaded);
    },
    allFieldsFilled() {
      return this.gun_names_form.every(gun => gun.name !== "");
    },
  },
  mounted() {
    var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
    this.canvas_width = lineLayoutCanvas.width;
    this.canvas_height = lineLayoutCanvas.height;
    this.ctx_line_layout = lineLayoutCanvas.getContext("2d");

    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData() {
      await this.getLine(this.lineId);
      this.initilizeGunsNames();
      await this.getLineLayoutImage();
      this.fillLineLayoutCanvas();
    },
    initilizeGunsNames() {
      this.gun_names_form = this.generateGuns(this.totalLineGuns);
      this.gun_names_loaded = this.generateGuns(this.totalLineGuns);
      if (this.line.gun_names) {
        this.gun_names_form = this.line.gun_names.map(name => ({ name }));
        this.gun_names_loaded = this.line.gun_names.map(name => ({ name }));
      }
    },
    generateGuns(total_guns) {
      return Array.from({ length: total_guns }, () => ({ name: "" }));
    },
    async saveNewNames() {
      try {
        if (!this.gunsNameFormUpdated) {
          return;
        }
        if (!this.allFieldsFilled) {
          await this.$swal({
            title: "Error",
            text: "Please fill all the fields",
            icon: "error",
            confirmButtonText: "OK",
          });
          return;
        }
        this.$store.commit("setLoading", true);
        const gun_names_array = this.gun_names_form.map(gun => gun.name);
        await axios.patch(`/api/v1/fp/line/${this.lineId}/`, {
          gun_names: gun_names_array,
        });
        this.$store.commit("setLoading", false);
        await this.$swal({
          title: "Changes saved",
          text: "New Gun names saved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        this.getData();
      } catch (error) {
        console.error(error);
      }
    },
    async getLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };
        var response = await axios.post("/api/v1/fp/computepowderamountlineimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      nextTick(() => {
        var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
        this.canvas_width = lineLayoutCanvas.width;
        this.canvas_height = lineLayoutCanvas.height;
        this.ctx_line_layout = lineLayoutCanvas.getContext("2d");

        let line_layout_imageData = new ImageData(
          Uint8ClampedArray.from(this.line_layout_image.values()),
          this.canvas_width,
          this.canvas_height,
        );
        this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
      });
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
