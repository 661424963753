<template>
  <div class="py-1 container-fluid">
    <div id="topinfo">
      <div class="row mb-3">
        <div class="col-12">
          <div :class="CopilotOptimalSettingResultComputed ? 'card bg-gradient-success' : 'card bg-gradient-info'">
            <div class="p-3 card-body">
              <div class="row">
                <div class="my-auto col-6">
                  <div class="numbers">
                    <p class="mb-0 text-sm text-white text-capitalize font-weight-bold opacity-7">
                      {{
                        $store.state.flightpath_standard_license
                          ? "Multi-powder Optimization"
                          : "General Optimization Name"
                      }}
                    </p>
                    <h5 class="mb-0 text-white font-weight-bolder">MS-Demo</h5>
                  </div>
                </div>
                <div class="col-6 text-end">
                  <p class="mb-0 text-sm text-white text-capitalize font-weight-bold opacity-7">Status</p>
                  <h5 class="mb-0 text-white text-end me-1">
                    {{ CopilotOptimalSettingResultComputed ? "Optimumfound" : "Ready to find optimum" }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.state.copilot_license">
      <div class="row mt-4 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Copilot Optimization</h5>
            <div v-if="true">
              <div class="row">
                <div class="col-4">
                  <label class="mt-2 mb-2">Name</label>
                  <div class="mt-2 mb-2">MS-Demo</div>
                </div>
              </div>
              <div class="row mt-4 justify-content-center">
                <h5 class="font-weight-bolder">Optimization Settings</h5>
                <div class="col w-20">
                  <label class="mt-2 mb-2"
                    >Line Speed [{{
                      this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes
                    }}]
                  </label>
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">
                    Covered height
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Vertical height for which the homogenity distribution will be optimized"
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                    [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].distance }}]</label
                  >
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">
                    Covered width
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="
                        'Only relevant for visualization purposes, determines the horizontal scale,' +
                        'or width, of the simulation image shown on the results section'
                      "
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                    [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].distance }}]</label
                  >
                </div>
                <div class="col w-20">
                  <label class="mt-2 mb-2">
                    Desired Thickness
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="
                        'Input the desired thickness of the coating layer. The optimization will' +
                        'try to find the optimal parameters to achieve this thickness'
                      "
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                    [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness }}]</label
                  >
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col w-15">
                  <input
                    v-model="copilotFormLineSpeed"
                    class="form-control"
                    type="number"
                    :max="maxLineSpeedAllowed"
                    :min="minLineSpeedAllowed"
                    step="0.1"
                    @change="ZeroLineSpeedAlertCheck"
                    @keydown="numericOnly"
                  />
                </div>
                <div class="col w-15">
                  <input
                    v-model="copilotFormCoatedHeight"
                    class="form-control"
                    type="number"
                    :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                    @change="ZeroCoatedHeightAlertCheck"
                    @keydown="numericOnly"
                  />
                </div>
                <div class="col w-15">
                  <input
                    v-model="copilotFormCoatedWidth"
                    class="form-control"
                    type="number"
                    :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                    @change="ZeroCoatedWidthAlertCheck"
                    @keydown="numericOnly"
                  />
                </div>
                <div class="col w-15">
                  <input
                    v-model="copilotFormCoatedThickness"
                    class="form-control"
                    :max="maxCoatedThicknessAllowed"
                    :min="minCoatedThicknessAllowed"
                    type="number"
                    :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                    @change="ZeroCoatedThicknessAlertCheck"
                    @keydown="numericOnly"
                  />
                </div>
              </div>
              <div
                v-if="CopilotFormIsFilled && CopilotFormIsUpdated && !calculation_in_progress"
                class="d-flex justify-content-end mt-4 mb-2"
              >
                <div class="p-2">
                  <button
                    class="mt-0 mb-0 btn bg-gradient-success ms-lg-auto me-lg-0 me-auto mt-lg-0"
                    @click="runCalculation"
                  >
                    <i class="fa fa-rocket"></i>
                    Compute Optimal Settings
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div
          v-if="calculation_in_progress || (CopilotOptimalSettingResultComputed && !CopilotFormIsUpdated)"
          class="card"
        >
          <div class="card-body">
            <div class="p-4 pb-0 card-header d-flex">
              <h5 class="font-weight-bolder">Copilot Optimal Settings</h5>
            </div>
            <div id="compute-optimal-settings-view">
              <div class="d-flex align-items-center justify-content-center mt-0">
                <div v-if="calculation_in_progress">
                  <div
                    class="p-3 pb-0 card-header"
                    style="text-align: center"
                  >
                    <h6>Calculation running</h6>
                  </div>
                  <div class="p-3 card-body">
                    <div style="width: 100%; height: 100%">
                      <div
                        class="spinner-border"
                        style="margin: auto; display: table; width: 100px; height: 100px"
                        role="status"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="calculation_in_progress"
              class="col-12"
            >
              <div>
                <soft-progress
                  :percentage="calculation_progress_percentage"
                  variant="gradient"
                  color="secondary"
                />
              </div>
            </div>
            <div v-if="CopilotOptimalSettingResultComputed && !calculation_in_progress">
              <div class="row pt-1">
                <div class="col-lg-9">
                  <div class="card">
                    <div class="mt-2 card-body">
                      <div
                        id="substrate_surface_img_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div class="col-lg-6">
                            <h6 style="text-align: center">Predicted Thickness Distribution</h6>
                            <img
                              alt="Image placeholder"
                              :src="local_storage_base_URL + optimal_setting.predicted_distribution_img"
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="col-lg-6">
                            <h6 style="text-align: center">Thickness Histogram</h6>
                            <img
                              alt="Image placeholder"
                              :src="local_storage_base_URL + optimal_setting.thickness_histogram_img"
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 ms-auto mt-xl-0 mt-4">
                  <h6>Optimal Settings Parameters</h6>
                  <div class="col-md-12 mt-md-0">
                    <mini-statistics-card
                      title="Active guns"
                      :value="JSON.stringify(optimal_setting.NumPistols)"
                      :icon="{ component: 'ni ni-bullet-list-67', background: 'bg-gradient-info' }"
                      class-content="ms-3"
                    />
                  </div>
                  <div class="mt-4 col-md-12 mt-md-0">
                    <mini-statistics-card
                      title="Gun movement range"
                      :value="`${optimalSettingPistolsMovementRange} ${
                        this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                      }`"
                      :percentage="{ value: '200', color: 'text-success' }"
                      :icon="{ component: 'ni ni-sound-wave', background: 'bg-gradient-info' }"
                      class-content="ms-3"
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mini-statistics-card
                        title="Gun Movement Speed"
                        :value="
                          optimalSettingPistolsVelocity +
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_seconds
                        "
                        :percentage="{ color: 'text-success' }"
                        :icon="{ component: 'ni ni-user-run', background: 'bg-gradient-info' }"
                        class-content="ms-3"
                      />
                    </div>
                    <div class="col-md-12">
                      <mini-statistics-card
                        title="Gun Movement Period"
                        :value="optimal_setting.pistols_period + 's'"
                        :percentage="{ color: 'text-success' }"
                        :icon="{
                          component: 'ni ni-user-run',
                          background: 'bg-gradient-info',
                        }"
                        class-content="ms-3"
                      />
                    </div>
                    <div class="col-md-12">
                      <mini-statistics-card
                        title="Gun to Substrate Distance"
                        :value="`${optimalPistolToSubstrateDistance} ${
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                        }`"
                        :percentage="{ color: 'text-success' }"
                        :icon="{
                          component: 'ni ni-user-run',
                          background: 'bg-gradient-info',
                        }"
                        class-content="ms-3"
                      />
                    </div>
                    <div class="mt-2 col-md-12 mt-md-0">
                      <mini-statistics-card
                        title="Air Flow"
                        :value="`${optimal_setting.AirFlow}`"
                        :percentage="{ color: 'text-success' }"
                        :icon="{ component: 'ni ni-diamond', background: 'bg-gradient-info' }"
                        class-content="ms-3"
                      />
                    </div>
                    <div class="mt-2 col-md-12 mt-md-0">
                      <mini-statistics-card
                        title="Powder Amount"
                        :value="`${optimal_setting.PowderAmount}`"
                        :percentage="{ color: 'text-success' }"
                        :icon="{ component: 'ni ni-diamond', background: 'bg-gradient-info' }"
                        class-content="ms-3"
                      />
                    </div>
                    <div class="mt-2 col-md-12 mt-md-0">
                      <mini-statistics-card
                        title="Voltage"
                        :value="optimal_setting.Voltage + ' kV'"
                        :percentage="{ color: 'text-success' }"
                        :icon="{ component: 'ni ni-diamond', background: 'bg-gradient-info' }"
                        class-content="ms-3"
                      />
                    </div>
                    <div class="mt-2 col-md-12 mt-md-0">
                      <mini-statistics-card
                        title="Current"
                        :value="optimal_setting.Current + ' μA'"
                        :percentage="{ color: 'text-success' }"
                        :icon="{ component: 'ni ni-diamond', background: 'bg-gradient-info' }"
                        class-content="ms-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4 horizontal dark" />
  </div>
</template>

<script>
import axios from "axios";
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import SoftProgress from "@/components/SoftProgress.vue";
import { numericOnly } from "@/views/composables.js";

export default {
  name: "GeneralOptimizationDetail",
  components: {
    MiniStatisticsCard,
    SoftProgress,
  },

  props: {
    flightpathId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      copilot_form: {
        coated_height: 100,
        coated_width: 100,
        desired_thickness: 80,
        line_speed: 2,
      },
      previous_copilot_form: {
        coated_height: null,
        coated_width: null,
        desired_thickness: null,
        line_speed: null,
      },
      optimal_setting: {},
      calculation_progress_interval: {},
      calculation_progress_percentage: 0,
      calculation_in_progress: false,
      local_storage_base_URL: axios.defaults.baseURL + "/static/",
      max_imperial_decimals: 2,
      max_metric_decimals: 2,
    };
  },

  computed: {
    copilotFormLineSpeed: {
      get() {
        if (this.copilot_form.line_speed == null || this.copilot_form.line_speed == "") {
          return this.copilot_form.line_speed;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.copilot_form.line_speed * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.copilot_form.line_speed.toFixed(this.max_metric_decimals));
        } else {
          return this.copilot_form.line_speed;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.copilot_form.line_speed = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.copilot_form.line_speed = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.copilot_form.line_speed = value;
          }
        }
      },
    },
    copilotFormCoatedHeight: {
      get() {
        if (this.copilot_form.coated_height == null || this.copilot_form.coated_height == "") {
          return this.copilot_form.coated_height;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.copilot_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.copilot_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.copilot_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.copilot_form.coated_height = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.copilot_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.copilot_form.coated_height = value;
          }
        }
      },
    },
    copilotFormCoatedWidth: {
      get() {
        if (this.copilot_form.coated_width == null || this.copilot_form.coated_width == "") {
          return this.copilot_form.coated_width;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.copilot_form.coated_width * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.copilot_form.coated_width.toFixed(this.max_metric_decimals));
        } else {
          return this.copilot_form.coated_width;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.copilot_form.coated_width = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.copilot_form.coated_width = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.copilot_form.coated_width = value;
          }
        }
      },
    },
    copilotFormCoatedThickness: {
      get() {
        if (this.copilot_form.desired_thickness == null || this.copilot_form.desired_thickness == "") {
          return this.copilot_form.desired_thickness;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.copilot_form.desired_thickness * this.$store.state.conversion_factors.um_to_mil).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.copilot_form.desired_thickness.toFixed(this.max_metric_decimals));
        } else {
          return this.copilot_form.desired_thickness;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.copilot_form.desired_thickness = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.copilot_form.desired_thickness = value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.copilot_form.desired_thickness = value;
          }
        }
      },
    },
    optimalPistolToSubstrateDistance() {
      if (this.optimal_setting.Distance == null || this.optimal_setting.Distance == "") {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.optimal_setting.Distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.optimal_setting.Distance.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minLineSpeedAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.$store.state.constraints.min_line_speed_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.min_line_speed_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    maxLineSpeedAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.$store.state.constraints.max_line_speed_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.max_line_speed_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minCoatedHeightAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.min_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.min_coated_height_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minCoatedWidthAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.min_coated_width_allowed * this.$store.state.conversion_factors.cm_to_in
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.min_coated_width_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minCoatedThicknessAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.min_desired_thickness_allowed * this.$store.state.conversion_factors.um_to_mil
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(
          this.$store.state.constraints.min_desired_thickness_allowed.toFixed(this.max_metric_decimals),
        );
      } else {
        return null;
      }
    },
    maxCoatedThicknessAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.max_desired_thickness_allowed * this.$store.state.conversion_factors.um_to_mil
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(
          this.$store.state.constraints.max_desired_thickness_allowed.toFixed(this.max_metric_decimals),
        );
      } else {
        return null;
      }
    },
    optimalSettingPistolsMovementRange() {
      if (this.optimal_setting.PistolsMovement == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.optimal_setting.PistolsMovement * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.optimal_setting.PistolsMovement.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    optimalSettingPistolsVelocity() {
      if (this.optimal_setting.PistolsVel == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.optimal_setting.PistolsVel * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.optimal_setting.PistolsVel.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },

    linePistolsMinVelocity() {
      if (this.line.pistols_min_velocity == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.line.pistols_min_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.line.pistols_min_velocity.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    linePistolsMaxVelocity() {
      if (this.line.pistols_max_velocity == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.line.pistols_max_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.line.pistols_max_velocity.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    CopilotFormIsFilled() {
      if (
        this.copilot_form.coated_height != null &&
        this.copilot_form.coated_width != null &&
        this.copilot_form.line_speed != null &&
        this.copilot_form.desired_thickness != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    CopilotFormIsUpdated() {
      if (JSON.stringify(this.copilot_form) != JSON.stringify(this.previous_copilot_form)) {
        return true;
      } else {
        return false;
      }
    },
    CopilotOptimalSettingResultComputed: function () {
      return this.optimal_setting.NumPistols != null;
    },
  },

  mounted() {
    setTooltip(this.$store.state.bootstrap);
  },

  updated() {
    setTooltip(this.$store.state.bootstrap);
  },

  methods: {
    numericOnly,
    StartCalculationInProgress() {
      this.calculation_progress_percentage = 0;
      this.calculation_progress_interval = setInterval(() => {
        this.calculation_progress_percentage += 0.2;
      }, 300);
    },
    ZeroLineSpeedAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes;
      if (this.copilot_form.line_speed == 0) {
        this.$swal({
          title: "Input value out of range",
          text: `Line speed [${distanceUnit}] cannot be 0 [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilot_form.line_speed = this.flightpath.line_speed;
      } else if (this.copilot_form.line_speed < this.$store.state.constraints.min_line_speed_allowed) {
        this.$swal({
          title: "Input value out of range",
          text: `Line speed [${distanceUnit}] cannot be less than ${this.minLineSpeedAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilot_form.line_speed = this.flightpath.line_speed;
      } else if (this.copilot_form.line_speed > this.$store.state.constraints.max_line_speed_allowed) {
        this.$swal({
          title: "Input value out of range",
          text: `Line speed [${distanceUnit}] cannot be greater than ${this.maxLineSpeedAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilot_form.line_speed = this.flightpath.line_speed;
      }
    },
    ZeroCoatedHeightAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      if (this.copilot_form.coated_height == 0 || this.copilot_form.coated_height == null) {
        this.$swal({
          title: "Input value out of range",
          text: `Coated height [${distanceUnit}] of optimization area must be greater than 0 [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilot_form.coated_height = this.flightpath.coated_height;
      } else if (this.copilot_form.coated_height < this.$store.state.constraints.min_coated_height_allowed) {
        this.$swal({
          title: "Input value out of range",
          text: `Coated height [${distanceUnit}] of optimization area must be greater than ${this.minCoatedHeightAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilot_form.coated_height = this.flightpath.coated_height;
      }
    },
    ZeroCoatedWidthAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      if (this.copilot_form.coated_width == 0) {
        this.$swal({
          title: "Input value out of range",
          text: `Simulation visualization coated width [${distanceUnit}] must be greater than 0 [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilotFormCoatedWidth = null;
      } else if (this.copilot_form.coated_width < this.$store.state.constraints.min_coated_width_allowed) {
        this.$swal({
          title: "Input value out of range",
          text: `Simulation visualization coated width [${distanceUnit}] must be greater than ${this.minCoatedWidthAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilotFormCoatedWidth = null;
      }
    },
    ZeroCoatedThicknessAlertCheck() {
      const thicknessUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness;
      if (this.copilot_form.desired_thickness == 0) {
        this.$swal({
          title: "Input value out of range",
          text: `Desired coated thickness [${thicknessUnit}] must be greater than 0 [${thicknessUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilotFormCoatedThickness = null;
      } else if (this.copilot_form.desired_thickness < this.$store.state.constraints.min_desired_thickness_allowed) {
        this.$swal({
          title: "Input value out of range",
          text: `Desired coated thickness [${thicknessUnit}] must be greater than ${this.minCoatedThicknessAllowed} [${thicknessUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilotFormCoatedThickness = null;
      } else if (this.copilot_form.desired_thickness > this.$store.state.constraints.max_desired_thickness_allowed) {
        this.$swal({
          title: "Input value out of range",
          text: `Desired coated thickness [${thicknessUnit}] must be less than ${this.maxCoatedThicknessAllowed} [${thicknessUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.copilotFormCoatedThickness = null;
      }
    },
    async runCalculation() {
      this.StartCalculationInProgress();
      this.calculation_in_progress = true;
      await axios
        .post("/api/v1/cp/computecopilotoptimalsettings/", {
          coated_height: this.copilot_form.coated_height / 100,
          coated_width: this.copilot_form.coated_width / 100,
          line_speed: this.copilot_form.line_speed / 60, //convert [m] to [cm]
          desired_thickness: this.copilot_form.desired_thickness,
        })
        .then(response => {
          this.optimal_setting = response.data.optimal_setting;
          this.optimal_setting.Distance = this.optimal_setting.Distance * 100;
          this.optimal_setting.PistolsMovement = this.optimal_setting.PistolsMovement * 100;
        })
        .catch(error => console.error(error));

      this.previous_copilot_form = JSON.parse(JSON.stringify(this.copilot_form));
      this.calculation_in_progress = false;
    },
  },
};
</script>
