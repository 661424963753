<template>
  <div
    class="card-body col-8 pt-0 p-3 mx-auto"
    style="cursor: pointer"
    @click="handleClick"
  >
    <ul class="list-group">
      <li
        class="list-group-item border-0 d-flex p-3 mb-1 border-radius-lg"
        style="background-color: rgb(230, 230, 230); cursor: pointer;"
      >
        <div
          class="col-9 p-2"
          style="min-width: 100px"
        >
          <span class="text-lg">
            {{ $t("Line Name") }}
          </span>
          <div
            onmouseover="style.textDecoration = 'underline'"
            onmouseout="style.textDecoration = 'none'"
          >
            <h6 class="text-2xl">{{ lineName }}</h6>
          </div>
        </div>
        <div class="col mt-4">
          <span class="text-xs">
            {{ $t("Created") }}
          </span>
          <div class="my-auto mb-0">
            <p style="font-size: 0.8rem">
              <b>{{ dateTime }}</b>
            </p>
          </div>
        </div>
        <div
          v-if="!$store.state.finetune_license"
          class="col-2 mx-3 text-center"
          style="margin-top: auto; margin-bottom: auto"
          @click.stop="$router.push({ name: 'LineDetailBluePrint', params: { lineId: lineId }})"
        >
          <a
            class="btn btn-link text-dark px-3 mb-0 my-auto"
          >
            <i
              class="fas fa-pencil-alt text-dark me-2"
              aria-hidden="true"
            ></i
            >{{ $t("Edit") }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import axios from "axios";


export default {
  name: "LineCardBluePrint",
  props: {
    lineName: {
      type: String,
      default: "",
    },
    lineId: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {};
  },
  methods: {
    async handleClick() {
      if (this.$store.state.finetune_license) {
        try {
          let response = await axios.post("/api/v1/finetune/gunsequalization/", {});
          this.gunsEqualizationsId = response.data.id;
        } catch (error) {
          console.error(error);
        }
        this.$router.push(`/finetune/${this.lineId}/${this.gunsEqualizationsId}`);
      } else {
        this.$router.push({
          name: "LineVisits",
          params: { lineId: this.lineId },
        });
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
        "Line Name":"Line Name",
        "Created":"Created",
    },
    "DE": {
        "Line Name":"Linienname",
        "Created":"Erstellt",
    },
    "ES": {
        "Line Name":"Nombre de la Línea",
        "Created":"Creada",
    },
    "FR": {
        "Line Name":"Nom de la Ligne",
        "Created":"Créée",
    },
    "IT": {
        "Line Name":"Nome della Linea",
        "Created":"Creata",
    },
    "PL": {
        "Line Name":"Nazwa Linii",
        "Created":"Utworzony",
    },
    "TR": {
        "Line Name":"Hat İsmi",
        "Created":"Oluşturuldu",
    },
    "ZH": {
        "Line Name":"线路名称",
        "Created":"创建",
    },
  }
</i18n>
