<template>
  <div>
    <h5 class="mb-1">{{ title }}</h5>
    <div
      v-if="editableGunThroughput"
      class="mb-2 px-2"
    >
      <div class="row">
        <div class="col-6">
          <label>
            Gun throughput
            {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}
            <i
              :title="
                `Powder throughput of each gun in ${$store.state.units_system[$store.state.user_data.unit_system].grams}/min. `
                + `The minimum gun throughput allowed is `
                + `${minGunThroughput} ${$store.state.units_system[$store.state.user_data.unit_system].grams}/min `
                + `for an equalized output between all guns.`"
              class="fa fa-info-circle"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-container="body"
              data-animation="true"
            >
            </i>
          </label>
          <input
            v-model="gun_throughput"
            type="number"
            :min="minGunThroughput"
            :max="10000"
            class="form-control form-control-lg"
            :placeholder="`${$store.state.units_system[$store.state.user_data.unit_system].grams}/min`"
            style="font-size: 1.25rem; min-width: 80px; max-width: 110px;"
            @change="$emit('update-gun-throughput', gun_throughput); edited_gun_throughput = true;"
            @keydown="numericPositiveOnly"
          />
        </div>
        <div v-if="mean_thickness != 0" class="col-6">
          <label>
            Expected Mean Thickness
            <i
              :title="
                `The estimated average thickness of the powder application,`
                +` given the last taken thickness measures and the gun throughput of `
                + `${gun_throughput} ${$store.state.units_system[$store.state.user_data.unit_system].grams}/min `
              "
              class="fa fa-info-circle"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-container="body"
              data-animation="true"
            >
            </i>
          </label>
          <input
            v-model="mean_thickness"
            type="number"
            :min="0"
            :max="10000"
            class="form-control form-control-lg"
            style="font-size: 1.25rem; min-width: 80px; max-width: 110px;"
            @change="$emit('update-mean-thickness', mean_thickness); edited_mean_thickness = true;"
            @keydown="numericPositiveOnly"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex px-2"
    >
      <mini-statistics-card
        title="Gun throughput"
        :value="`${gunThroughput} [${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]`"
        percentage=""
        :icon="{ component: 'ni ni ni-support-16', background: 'bg-gradient-info' }"
        class-content="px-2"
      />
    </div>
    <h6 class="px-2">Powder Amount Parameters to equalize all guns:</h6>
    <div class="row">
      <div
        v-for="(n, index) in totalLineGuns"
        :key="n"
        class="col-lg-4 col-md-4 col-sm-3"
        style="min-width: 40px"
      >
        <mini-statistics-card
          :title="` Gun ${index + 1}`"
          :value="`${powderAmountParameters[index] < 0 ? 0 : powderAmountParameters[index]}`"
          :percentage="{ value: '200', color: 'text-success' }"
          :icon="{ component: 'ni ni-settings', background: 'bg-gradient-info' }"
          :class-content="powderAmountParameters[index] < 0 ? 'px-1' : 'px-1'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { nextTick } from "vue";
import { numericPositiveOnly } from "@/views/composables.js";

export default {
  name: "GunThroughput",
  components: {
    MiniStatisticsCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    gunThroughput: {
      type: Number,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    powderAmountParameters: {
      type: Array,
      required: true,
    },
    editableGunThroughput: {
      type: Boolean,
      required: false,
      default: false,
    },
    minGunThroughput: {
      type: Number,
      required: false,
      default: 0,
    },
    meanThickness: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: [
    "update-gun-throughput",
    "update-mean-thickness",
  ],
  data() {
    return {
      gun_throughput: 0,
      mean_thickness: 0,
      edited_gun_throughput: false,
    };
  },
  computed: {
    totalLineGuns() {
      if (this.line == null || this.line.total_pistols == null) {
        return 0;
      }

      return this.line.total_pistols.reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    'gunThroughput' () {
      this.gun_throughput = this.gunThroughput;
    },
    'meanThickness' () {
      this.mean_thickness = this.meanThickness;
    },
  },
  mounted() {
    this.gun_throughput = this.gunThroughput;
    this.mean_thickness = this.meanThickness;
    this.initialGunOutput = JSON.parse(JSON.stringify(this.gunThroughput));

    nextTick(() => {
      setTooltip(this.$store.state.bootstrap);
    });
  },
  methods: {
    numericPositiveOnly,
  },
};
</script>
