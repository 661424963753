<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 text-start mt-3 mb-0">
            <button
              class="btn btn-outline-secondary btn-sm px-3 mt-1 mb-3"
              @click="$router.push({ name: 'LineVisits', params: { lineId: lineId }});"
            >
              <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
              Go back to visits
            </button>
          </div>
          <div class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 d-flex flex-column card card-body blur shadow-blur">
            <div class="card-header text-center">
              <h4 class="font-weight-bolder">Reciprocator Optimizations Library</h4>
            </div>
            <div class="row gx-4">
              <div class="row text-center">
                <div
                  class="col p-3"
                  style="min-width: 100px; height: 150px"
                >
                  <button
                    class="w-60px h-100 mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
                    type="button"
                    style="width: 200px; height: 100px; background-color: rgb(231, 245, 221); font-size: 0.9rem"
                    @click="routeToNewOptimization"
                  >
                    {{ $t("New Reciprocator Optimization") }}
                  </button>
                </div>
              </div>
              <div class="row">
                <ReciprocatorOptimizationList
                  :line-id="lineId"
                  :visit-id="visitId"
                  :table-title="'Optimization List for Current Visit'"
                >
                </ReciprocatorOptimizationList>
                <ReciprocatorOptimizationList
                  :line-id="lineId"
                  :visit-id="visitId"
                  :table-title="'Optimization List for Previous Visits'"
                >
                </ReciprocatorOptimizationList>
                <soft-button
                  type="button"
                  color="success"
                  variant="gradient"
                  class="mb-0 ms-auto js-btn-next w-45"
                  style="max-width: 300px"
                  title="Continue to thickness adjustment"
                  @click="$router.push({ name: 'ThicknessAdjustment', params: { lineId: lineId, visitId: visitId }});"
                >
                  Continue to Thickness Adjustment &nbsp;
                  <font-awesome-icon
                    :icon="['fas', 'circle-chevron-right']"
                    size="lg"
                  />
                </soft-button>
              </div>
            </div>
          </div>
          <stepper-line-visit
            :line-id="lineId"
            :visit="visit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReciprocatorOptimizationList from "./components/ReciprocatorOptimizationList.vue";
import setTooltip from "@/assets/js/tooltip.js";
import logo from "@/assets/img/logo-ct-white.png";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import { useGetVisit } from "@/views/composables.js";
import SoftButton from "@/components/SoftButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

library.add([faCircleChevronRight]);

export default {
  name: "ReciprocatorOptimizationsLibrary",
  components: {
    ReciprocatorOptimizationList,
    StepperLineVisit,
    SoftButton,
    FontAwesomeIcon,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    return { visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        visit: null,
        line: [],
        logo,
      };
    }
  },
  computed: {
    isFixedLineLayout() {
      return this.line.pistols_layout == "f" || this.line.pistols_layout == "fixedmixed";
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData() {
      Promise.all([this.getLine(), this.getVisit(this.visitId)]);
    },
    async getLine() {
      try {
        let response = await axios.get("/api/v1/fp/line/" + this.lineId);
        this.line = response.data;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getLine();
          }.bind(this),
          5000,
        );
      }
    },
    routeToNewOptimization() {
      if (this.isFixedLineLayout) {
        this.$router.push("/applications/blueprint/fixedlineoptimization/" + this.lineId + "/" + this.visitId);
      } else {
        this.$router.push({
        name: "ReciprocatorOptimizationDetail",
        params: {
          lineId: this.lineId,
          visitId: this.visitId,
          flightpathIdProp: undefined,
          optimizationTypeProp: undefined,
        },
      });
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
        "Optimizations Library":"Optimizations Library",
        "New Reciprocator Optimization":"New Reciprocator Optimization",
        "Line Name":"Line Name",
        "Create Optimization":"Create Optimization",
    },
    "DE": {
        "Optimizations Library":"Optimierungsliste",
        "New Reciprocator Optimization":"Neue Optimierung",
        "Line Name":"Name der Linie",
        "Create Optimization":"Optimierung Erstellen",
    },
    "ES": {
        "Optimizations Library":"Lista de Optimizaciones",
        "New Reciprocator Optimization":"Nueva Optimización del reciprocador",
        "Line Name":"Nombre de la línea",
        "Create Optimization":"Crear Optimización",
    },
    "FR": {
        "Optimizations Library":"Liste des Optimisations",
        "New Reciprocator Optimization":"Nouvelle Optimisation du Réciprocateur",
        "Line Name":"Nom de la Ligne",
        "Create Optimization":"Créer l'Optimisation",
    },
    "IT": {
        "Optimizations Library":"Elenco delle Ottimizzazioni",
        "New Reciprocator Optimization":"Nuova Ottimizzazione del Reciprocator",
        "Line Name":"Nome della Linea",
        "Create Optimization":"Creare l'Ottimizzazione",
    },
    "PL": {
        "Optimizations Library":"Lista Optymalizacji",
        "New Reciprocator Optimization":"Nowa optymalizacja Reciprocatora",
        "Line Name":"Nazwa Linii",
        "Create Optimization":"Tworzenie Optymalizacji",
    },
    "TR": {
        "Optimizations Library":"Optimizasyonlar Listesi",
        "New Reciprocator Optimization":"Yeni Reciprocator Optimizasyonu",
        "Line Name":"Hat Adı",
        "Create Optimization":"Optimizasyon Oluşturun",
    },
    "ZH": {
        "Optimizations Library":"优化列表",
        "New Reciprocator Optimization":"新优化",
        "Line Name":"线路名称",
        "Create Optimization":"创建优化",
    }
  }
</i18n>
