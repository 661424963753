<template>
  <div class="container-fluid">
    <div class="mt-4 page-header min-height-250 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '10%'
    }">
      <span class="mask opacity-5" style="background-color: rgb(44, 222, 44);"></span>
      <div style="display: flex; align-items: center; justify-content: center;">
        <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 30%;">
          <h3 style="color: rgb(255, 255, 255); text-align: center;">{{ $t("Powder Amount Calibrations Library") }}</h3>
          <h5 style="color: rgb(255, 255, 255); text-align: center;">{{ line.name }}</h5>
        </div>
      </div>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="row text-center">
          <div class="col p-3" style="min-width: 100px; height: 150px;">
            <button
              class="btn py-5 w-60px mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="width: 200px; background-color: rgb(231, 245, 221); font-size: 0.75rem;"
              @click="routeToNewPowderAmount"
            >
              {{ $t("New Powder Amount Calibration") }}
            </button>
          </div>
        </div>
        <div class="row">
          <PowderAmountCalibrationsList
            :line-id="lineId">
          </PowderAmountCalibrationsList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useGetLine } from "@/views/composables.js";
import PowderAmountCalibrationsList from './components/PowderAmountCalibrationsList.vue';
import setTooltip from "@/assets/js/tooltip.js";
import logo from "@/assets/img/logo-ct-white.png";

export default {
  name: "PowderAmountCalibrationsLibrary",
  components: {
    PowderAmountCalibrationsList,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    }
  },
  setup() {
    const { line, getLine } = useGetLine();
    return { line, getLine };
  },
  data()  {
    this.$i18n.locale = this.$store.state.user_data.language;{
    return {
      logo,
    }};
  },
  computed: {
    isFixedLineLayout() {
      return (this.line.pistols_layout == "f" || this.line.pistols_layout == 'fixedmixed');
    },
  },
  watch:{
    '$store.state.user_data.language' () {
      this.$i18n.locale = this.$store.state.user_data.language;
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData () {
      await this.getLine(this.lineId);
    },
    routeToNewPowderAmount() {
        this.$router.push({
          name: 'PowderAmountCalibrationDetail',
          params: { lineId: this.lineId, powderAmountCalibrationIdProp: undefined},
        });
      },
  },
};
</script>

<i18n>
  {
    "EN": {
       "Powder Amount Calibrations Library":"Powder Amount Calibrations Library",
       "New Powder Amount Calibration":"New Powder Amount Calibration",
    },
    "DE": {
        "Powder Amount Calibrations Library":"Pulverbetragkalibrierungen",
         "New Powder Amount Calibration":"Neue Pulverbetragkalibrierung",
    },
    "ES": {
        "Powder Amount Calibrations Library":"Biblioteca de Calibraciones de Flujo de Pintura",
        "New Powder Amount Calibration":"Nueva Calibración de Flujo de Pintura",
    },
    "FR": {
        "Powder Amount Calibrations Library":"Bibliothèque de Calibrations de Quantité de Poudre",
        "New Powder Amount Calibration":"Nouvelle Calibration de Quantité de Poudre",
    },
    "IT": {
        "Powder Amount Calibrations Library":"Libreria di Calibrazioni della Quantità di Polvere",
        "New Powder Amount Calibration":"Nuova Calibrazione della Quantità di Polvere",
    },
    "PL": {
        "Powder Amount Calibrations Library":"Biblioteka Kalibracji Ilości Proszku",
        "New Powder Amount Calibration":"Nowa Kalibracja Ilości Proszku",
    },
    "TR": {
        "Powder Amount Calibrations Library":"Toz Miktarı Kalibrasyonları Kütüphanesi",
        "New Powder Amount Calibration":"Yeni Toz Miktarı Kalibrasyonu",
    },
    "ZH": {
        "Powder Amount Calibrations Library":"粉量校准库",
        "New Powder Amount Calibration":"新粉量校准",
    }
  }
</i18n>