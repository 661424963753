<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="row gy-2 gx-2">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div v-if="!calculation_in_progress">
                <h5 class="text-left">{{ $t("New static calibration of a Powder Model for") }} {{ line.name }}</h5>
                <div class="row mt-2">
                  <div class="col-sm-4">
                    <label class="h6">{{ $t("Powder model name") }}</label>
                    <input
                      v-model="powdermodel.name"
                      :class="name_input_error ? 'form-control is-invalid' : 'form-control'"
                      type="text"
                      @input="name_input_error = false"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div
                    v-if="$store.state.flightpath_pro_license"
                    class="col-md-3"
                  >
                    <label class="mt-4">{{ $t("Nozzle Type") }}</label>
                    <input
                      v-model.lazy="powdermodel_calibration_measures.nozzles_type"
                      :class="nozzles_type_input_error ? 'form-control is-invalid' : 'form-control'"
                      type="text"
                      @input="nozzles_type_input_error = false"
                    />
                  </div>
                  <div
                    v-if="$store.state.flightpath_pro_license"
                    class="col-md-3"
                  >
                    <label class="mt-4">{{ $t("Powder Manufacturer") }}</label>
                    <select
                      id="choices-category"
                      v-model="powdermodel_calibration_measures.powder_manufacturer"
                      :class="powder_manufacturer_input_error ? 'form-control is-invalid' : 'form-control'"
                      name="powder-manufacturer-choices"
                      style="appearance: listbox"
                      @input="powder_manufacturer_input_error = false"
                    >
                      <option
                        v-for="powder_manufacturer_option in powder_manufacturer_options"
                        :key="powder_manufacturer_option"
                        :selected="powdermodel_calibration_measures.powder_manufacturer"
                        :value="powder_manufacturer_option"
                      >
                        {{ $t(powder_manufacturer_option) }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="$store.state.flightpath_pro_license"
                    class="col-md-3"
                  >
                    <label class="mt-4">{{ powderLabel }}</label>
                    <input
                      v-model.lazy="powdermodel_calibration_measures.powder_article_id"
                      :class="powder_article_id_input_error ? 'form-control is-invalid' : 'form-control'"
                      type="text"
                      @input="powder_article_id_input_error = false"
                    />
                  </div>
                  <div
                    v-if="$store.state.flightpath_pro_license"
                    class="col-md-3"
                  >
                    <label class="mt-4">{{ $t("Batch ID") }}</label>
                    <input
                      v-model.lazy="powdermodel_calibration_measures.batch_id"
                      :class="batch_id_input_error ? 'form-control is-invalid' : 'form-control'"
                      type="text"
                      @input="batch_id_input_error = false"
                    />
                  </div>
                </div>
                <div class="accordion">
                  <div class="accordion-item mb-0">
                    <h5 id="panelsStayOpen-headingOne">
                      <button
                        class="accordion-button collapsed mb-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseParams"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseParams"
                        style="padding: 0"
                      >
                        <p
                          class="font-weight-bolder mt-3"
                          style="
                            font-weight: 700 !important;
                            font-size: 1.05rem;
                            vertical-align: middle;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("Optional Parameters") }}
                          <i
                            class="fa fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                          <i
                            class="fa fa-chevron-up"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </button>
                    </h5>
                    <div
                      id="panelsStayOpen-collapseParams"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div
                        class="accordion-body"
                        style="padding: 0"
                      >
                        <div class="mb-0 row">
                          <div
                            v-if="$store.state.flightpath_standard_license"
                            class="col-md-3"
                          >
                            <label class="mt-4">{{ $t("Nozzle Type") }}</label>
                            <input
                              v-model.lazy="powdermodel_calibration_measures.nozzles_type"
                              style="background-color: rgb(241, 241, 241)"
                              :class="`form-control`"
                              type="text"
                            />
                          </div>
                          <div
                            v-if="$store.state.flightpath_standard_license"
                            class="col-md-3"
                          >
                            <label class="mt-4">{{ $t("Powder Manufacturer") }}</label>
                            <input
                              v-model.lazy="powdermodel_calibration_measures.powder_manufacturer"
                              style="background-color: rgb(241, 241, 241)"
                              :class="`form-control`"
                              type="text"
                            />
                          </div>
                          <div
                            v-if="$store.state.flightpath_standard_license"
                            class="col-md-3"
                          >
                            <label class="mt-4">{{ $t("Powder ID") }}</label>
                            <input
                              v-model.lazy="powdermodel_calibration_measures.powder_article_id"
                              style="background-color: rgb(241, 241, 241)"
                              :class="`form-control`"
                              type="text"
                            />
                          </div>
                          <div
                            v-if="$store.state.flightpath_pro_license"
                            class="col-md-3"
                          >
                            <label class="mt-4">{{ operationalHoursLabel }}</label>
                            <input
                              v-model.lazy="powdermodel_calibration_measures.pump_operational_hours"
                              :class="'form-control'"
                              type="text"
                              style="background-color: rgb(241, 241, 241)"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="mt-4"
                              >{{ $t("Gun to substrate distance") }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].distance
                              }}]</label
                            >
                            <input
                              v-model.lazy="pistolToSubstrateDistance"
                              :class="`form-control`"
                              style="background-color: rgb(241, 241, 241)"
                              type="number"
                              @keydown="numericPositiveOnly"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="mt-4">
                              {{ $t("Air parameter") }}
                              <i
                                :title="$t('Airflow or air volume selected in the powder coating machine')"
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-container="body"
                                data-animation="true"
                              >
                              </i>
                            </label>
                            <input
                              v-model="powdermodel_calibration_measures.air_parameter"
                              :class="`form-control`"
                              type="number"
                              min="0"
                              style="background-color: rgb(241, 241, 241)"
                              @input="air_parameter_input_error = false"
                              @keydown="numericPositiveOnly"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="mt-4">
                              {{ $t("Powder Amount") }}
                              <i
                                :title="$t('Quantity of powder dispensed by the gun per unit time')"
                                class="fa fa-info-circle"
                                aria-hidden="true"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-container="body"
                                data-animation="true"
                              >
                              </i>
                            </label>
                            <input
                              v-model="powdermodel_calibration_measures.powder_amount"
                              :class="`form-control`"
                              type="number"
                              min="0"
                              style="background-color: rgb(241, 241, 241)"
                              @keydown="numericPositiveOnly"
                            />
                          </div>
                          <div class="col-md-3">
                            <label class="mt-4">{{ $t("Booth side used for calibration") }} </label>
                            <select
                              id="choices-category"
                              v-model="powdermodel_calibration_measures.booth_side"
                              class="form-control"
                              name="choices-category"
                              style="appearance: listbox; background-color: rgb(241, 241, 241)"
                            >
                              <option
                                v-for="booth_side_option in booth_side_options"
                                :key="booth_side_option"
                                :selected="powdermodel_calibration_measures.booth_side"
                                :value="booth_side_option"
                              >
                                {{ $t(booth_side_option) }}
                              </option>
                            </select>
                          </div>
                          <div
                            v-if="$store.state.flightpath_pro_license"
                            class="col-md-3"
                          >
                            <label class="mt-4"
                              >{{ $t("Booth Temperature") }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].temperature
                              }}]</label
                            >
                            <input
                              v-model.lazy="boothTemperature"
                              :class="`form-control`"
                              type="number"
                              style="background-color: rgb(241, 241, 241)"
                              @keydown="numericOnly"
                            />
                          </div>
                          <div
                            v-if="$store.state.flightpath_pro_license"
                            class="col-md-3"
                          >
                            <label class="mt-4">{{ $t("Booth Humidity") }} [%]</label>
                            <input
                              v-model="powdermodel_calibration_measures.booth_humidity"
                              :class="`form-control`"
                              type="number"
                              min="0"
                              style="background-color: rgb(241, 241, 241)"
                              @keydown="numericPositiveOnly"
                            />
                          </div>
                          <div
                            v-if="$store.state.flightpath_pro_license"
                            class="col-md-3"
                          >
                            <label class="mt-4">{{ $t("Guns Spacing") }} </label>
                            <select
                              id="choices-category"
                              v-model="powdermodel_calibration_measures.guns_spacing"
                              class="form-control"
                              name="choices-category"
                              style="appearance: listbox; background-color: rgb(241, 241, 241)"
                            >
                              <option
                                v-for="guns_spacing_option in guns_spacing_options"
                                :key="guns_spacing_option"
                                :selected="powdermodel_calibration_measures.guns_spacing"
                                :value="guns_spacing_option"
                              >
                                {{ $t(guns_spacing_option) }}
                              </option>
                            </select>
                          </div>
                          <div :class="$store.state.flightpath_standard_license ? 'col-md-3' : 'col-md-12'">
                            <label class="mt-4">{{ $t("Additional Info") }}</label>
                            <input
                              v-model="powdermodel_calibration_measures.additional_info"
                              style="background-color: rgb(241, 241, 241)"
                              :class="`form-control`"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="calculation_in_progress">
                <div style="width: 100%; height: 100%">
                  <div
                    class="spinner-border"
                    style="margin: auto; display: table; width: 100px; height: 100px"
                    role="status"
                  >
                    <span class="sr-only">{{ $t("Fitting PowderModel Distribution from measurements") }}...</span>
                  </div>
                </div>
                <p
                  class="mt-3"
                  style="margin: auto; text-align: center"
                >
                  {{ $t("Fitting PowderModel Distribution from measurements") }}...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gy-1 gx-2">
        <div class="col">
          <div v-if="!calculation_in_progress">
            <div class="card">
              <div class="card-body">
                <h5 class="font-weight">{{ $t("Calibration Plate Measurements") }}</h5>
                <div class="grid">
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 1 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="topleftcell">
                      <input
                        v-model="measurement1"
                        class="form-control circle"
                        type="number"
                        :class="measurement_1_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement1 !== null && measurement1 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_1_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 2 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="topcell">
                      <input
                        v-model="measurement2"
                        class="form-control circle"
                        type="number"
                        :class="measurement_2_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement2 !== null && measurement2 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_2_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 3 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="topcell">
                      <input
                        v-model="measurement3"
                        class="form-control circle"
                        type="number"
                        :class="measurement_3_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement3 !== null && measurement3 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_3_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 4 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="topcell">
                      <input
                        v-model="measurement4"
                        class="form-control circle"
                        type="number"
                        :class="measurement_4_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement4 !== null && measurement4 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_4_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 5 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="toprightcell">
                      <input
                        v-model="measurement5"
                        class="form-control circle"
                        type="number"
                        :class="measurement_5_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement5 !== null && measurement5 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_5_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 6 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="leftcell">
                      <input
                        v-model="measurement6"
                        class="form-control circle"
                        type="number"
                        :class="measurement_6_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement6 !== null && measurement6 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_6_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 7 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement7"
                        class="form-control circle"
                        type="number"
                        :class="measurement_7_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement7 !== null && measurement7 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_7_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 8 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement8"
                        class="form-control circle"
                        type="number"
                        :class="measurement_8_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement8 !== null && measurement8 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_8_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 9 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement9"
                        class="form-control circle"
                        type="number"
                        :class="measurement_9_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color': measurement9 !== null && measurement9 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_9_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 10 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="rightcell">
                      <input
                        v-model="measurement10"
                        class="form-control circle"
                        type="number"
                        :class="measurement_10_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement10 !== null && measurement10 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_10_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 11 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="leftcell">
                      <input
                        v-model="measurement11"
                        class="form-control circle"
                        type="number"
                        :class="measurement_11_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement11 !== null && measurement11 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_11_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 12 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement12"
                        class="form-control circle"
                        type="number"
                        :class="measurement_12_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement12 !== null && measurement12 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_12_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 13 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement13"
                        class="form-control circle"
                        type="number"
                        :class="measurement_13_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement13 !== null && measurement13 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_13_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 14 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement14"
                        class="form-control circle"
                        type="number"
                        :class="measurement_14_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement14 !== null && measurement14 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_14_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 15 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="rightcell">
                      <input
                        v-model="measurement15"
                        class="form-control circle"
                        type="number"
                        :class="measurement_15_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement15 !== null && measurement15 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_15_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 16 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="leftcell">
                      <input
                        v-model="measurement16"
                        class="form-control circle"
                        type="number"
                        :class="measurement_16_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement16 !== null && measurement16 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_16_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 17 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement17"
                        class="form-control circle"
                        type="number"
                        :class="measurement_17_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement17 !== null && measurement17 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_17_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 18 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement18"
                        class="form-control circle"
                        type="number"
                        :class="measurement_18_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement18 !== null && measurement18 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_18_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 19 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="cell">
                      <input
                        v-model="measurement19"
                        class="form-control circle"
                        type="number"
                        :class="measurement_19_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement19 !== null && measurement19 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_19_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 20 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="rightcell">
                      <input
                        v-model="measurement20"
                        class="form-control circle"
                        type="number"
                        :class="measurement_20_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement20 !== null && measurement20 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_20_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 21 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="bottomleftcell">
                      <input
                        v-model="measurement21"
                        class="form-control circle"
                        type="number"
                        :class="measurement_21_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement21 !== null && measurement21 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_21_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 22 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="bottomcell">
                      <input
                        v-model="measurement22"
                        class="form-control circle"
                        type="number"
                        :class="measurement_22_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement22 !== null && measurement22 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_22_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 23 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="bottomcell">
                      <input
                        v-model="measurement23"
                        class="form-control circle"
                        type="number"
                        :class="measurement_23_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement23 !== null && measurement23 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_23_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 24 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="bottomcell">
                      <input
                        v-model="measurement24"
                        class="form-control circle"
                        type="number"
                        :class="measurement_24_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement24 !== null && measurement24 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_24_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                  <div class="col text-center">
                    <label class="mt-4"
                      >{{ $t("Point") }} 25 [{{
                        $store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]</label
                    >
                    <div class="bottomrightcell">
                      <input
                        v-model="measurement25"
                        class="form-control circle"
                        type="number"
                        :class="measurement_25_input_error ? `form-control is-invalid` : `form-control`"
                        :style="{
                          'background-color':
                            measurement25 !== null && measurement25 !== '' ? 'lightgreen' : 'lightgray',
                        }"
                        min="0"
                        @input="measurement_25_input_error = false"
                        @keydown="numericPositiveOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!calculation_in_progress"
            class="mt-2 d-flex flex-column justify-content-center"
          >
            <button
              type="button"
              class="mt-4 mb-2 btn bg-gradient-success"
              @click="postPowderModel"
            >
              {{ $t("Create Powder Distribution Model") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";
import router from "@/router";
import { numericOnly, numericPositiveOnly } from "@/views/composables.js";

export default {
  name: "StaticPowderModelAdd",
  components: {},
  props: {
    lineId: {
      type: String,
      default: null,
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      powdermodel: {
        id: null,
        name: null,
        line: null,
        h_distance: null,
        h_air: null,
        h_bias: null,
        v_distance: null,
        v_air: null,
        v_bias: null,
        beta_distance: null,
        beta_air: null,
        beta_bias: null,
        m_distance: null,
        m_air: null,
        m_bias: null,
      },
      powdermodel_calibration_measures: {
        calibration_type: "static",
        powder_amount: null,
        powder_manufacturer: null,
        powder_article_id: null,
        batch_id: null,
        additional_info: null,
        booth_side: null,
        booth_temperature: null,
        booth_humidity: null,
        guns_spacing: null,
        pistol_to_substrate_distance: null,
        air_parameter: null,
        measurement_1: null,
        measurement_2: null,
        measurement_3: null,
        measurement_4: null,
        measurement_5: null,
        measurement_6: null,
        measurement_7: null,
        measurement_8: null,
        measurement_9: null,
        measurement_10: null,
        measurement_11: null,
        measurement_12: null,
        measurement_13: null,
        measurement_14: null,
        measurement_15: null,
        measurement_16: null,
        measurement_17: null,
        measurement_18: null,
        measurement_19: null,
        measurement_20: null,
        measurement_21: null,
        measurement_22: null,
        measurement_23: null,
        measurement_24: null,
        measurement_25: null,
      },
      line: [],
      calculation_in_progress: false,
      booth_side_options: ["right", "left"],
      guns_spacing_options: ["evenly spaced", "unevenly spaced"],
      powder_manufacturer_options: ["AkzoNobel", "Other"],
      line_speed_input_error: false,
      name_input_error: false,
      powder_article_id_input_error: false,
      batch_id_input_error: false,
      nozzles_type_input_error: false,
      powder_manufacturer_input_error: false,
      measurement_1_input_error: false,
      measurement_2_input_error: false,
      measurement_3_input_error: false,
      measurement_4_input_error: false,
      measurement_5_input_error: false,
      measurement_6_input_error: false,
      measurement_7_input_error: false,
      measurement_8_input_error: false,
      measurement_9_input_error: false,
      measurement_10_input_error: false,
      measurement_11_input_error: false,
      measurement_12_input_error: false,
      measurement_13_input_error: false,
      measurement_14_input_error: false,
      measurement_15_input_error: false,
      measurement_16_input_error: false,
      measurement_17_input_error: false,
      measurement_18_input_error: false,
      measurement_19_input_error: false,
      measurement_20_input_error: false,
      measurement_21_input_error: false,
      measurement_22_input_error: false,
      measurement_23_input_error: false,
      measurement_24_input_error: false,
      measurement_25_input_error: false,
      max_thickness_metric_decimals: 0,
      max_distance_metric_decimals: 1,
      max_line_speed_metric_decimals: 1,
      max_temperature_decimals: 1,
      max_imperial_decimals: 2,
    };
  },

  computed: {
    pistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_calibration_measures.pistol_to_substrate_distance == null ||
          this.powdermodel_calibration_measures.pistol_to_substrate_distance == ""
        ) {
          return this.powdermodel_calibration_measures.pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.pistol_to_substrate_distance *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.pistol_to_substrate_distance.toFixed(
              this.max_distance_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.pistol_to_substrate_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.pistol_to_substrate_distance =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.pistol_to_substrate_distance = value;
          } else {
            this.powdermodel_calibration_measures.pistol_to_substrate_distance = null;
          }
        }
      },
    },
    boothTemperature: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_temperature == null ||
          this.powdermodel_calibration_measures.booth_temperature == ""
        ) {
          return this.powdermodel_calibration_measures.booth_temperature;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.booth_temperature * this.$store.state.conversion_factors.c_to_f +
              this.$store.state.conversion_factors.c_to_f_offset
            ).toFixed(this.max_temperature_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.booth_temperature.toFixed(this.max_temperature_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.booth_temperature = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.booth_temperature =
              (value - this.$store.state.conversion_factors.c_to_f_offset) /
              this.$store.state.conversion_factors.c_to_f;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.booth_temperature = value;
          } else {
            this.powdermodel_calibration_measures.booth_temperature = null;
          }
        }
      },
    },
    operationalHoursLabel: {
      get() {
        if (this.line.pump_type == "injector_venturi") {
          return this.$t("Operational Hours Injector");
        } else if (this.line.pump_type == "dense_phase") {
          return this.$t("Operational Hours Since Last Maintanance");
        } else {
          return this.$t("Pump Operational Hours");
        }
      },
    },
    powderLabel: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_manufacturer === null ||
          this.powdermodel_calibration_measures.powder_manufacturer === ""
        ) {
          return this.$t("Powder ID");
        } else if (this.powdermodel_calibration_measures.powder_manufacturer.toLowerCase().includes("akzo")) {
          return "Interpon ID";
        } else {
          return this.$t("Powder ID");
        }
      },
    },
    measurement1: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_1 == null ||
          this.powdermodel_calibration_measures.measurement_1 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_1 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_1.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_1 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_1 = null;
          }
        }
      },
    },
    measurement2: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_2 == null ||
          this.powdermodel_calibration_measures.measurement_2 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_2 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_2.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_2 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_2 = null;
          }
        }
      },
    },
    measurement3: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_3 == null ||
          this.powdermodel_calibration_measures.measurement_3 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_3 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_3.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_3 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_3 = null;
          }
        }
      },
    },
    measurement4: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_4 == null ||
          this.powdermodel_calibration_measures.measurement_4 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_4 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_4.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_4 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_4 = null;
          }
        }
      },
    },
    measurement5: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_5 == null ||
          this.powdermodel_calibration_measures.measurement_5 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_5 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_5.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_5 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_5 = null;
          }
        }
      },
    },
    measurement6: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_6 == null ||
          this.powdermodel_calibration_measures.measurement_6 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_6 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_6.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_6 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_6 = null;
          }
        }
      },
    },
    measurement7: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_7 == null ||
          this.powdermodel_calibration_measures.measurement_7 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_7 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_7.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_7 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_7 = null;
          }
        }
      },
    },
    measurement8: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_8 == null ||
          this.powdermodel_calibration_measures.measurement_8 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_8;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_8 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_8.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_8 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_8 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_8 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_8 = null;
          }
        }
      },
    },
    measurement9: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_9 == null ||
          this.powdermodel_calibration_measures.measurement_9 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_9;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_9 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_9.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_9 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_9 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_9 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_9 = null;
          }
        }
      },
    },
    measurement10: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_10 == null ||
          this.powdermodel_calibration_measures.measurement_10 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_10;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_10 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_10.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_10 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_10 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_10 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_10 = null;
          }
        }
      },
    },
    measurement11: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_11 == null ||
          this.powdermodel_calibration_measures.measurement_11 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_11;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_11 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_11.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_11 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_11 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_11 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_11 = null;
          }
        }
      },
    },
    measurement12: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_12 == null ||
          this.powdermodel_calibration_measures.measurement_12 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_12;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_12 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_12.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_12 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_12 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_12 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_12 = null;
          }
        }
      },
    },
    measurement13: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_13 == null ||
          this.powdermodel_calibration_measures.measurement_13 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_13;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_13 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_13.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_13 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_13 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_13 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_13 = null;
          }
        }
      },
    },
    measurement14: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_14 == null ||
          this.powdermodel_calibration_measures.measurement_14 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_14;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_14 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_14.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_14 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_14 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_14 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_14 = null;
          }
        }
      },
    },
    measurement15: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_15 == null ||
          this.powdermodel_calibration_measures.measurement_15 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_15;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_15 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_15.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_15 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_15 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_15 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_15 = null;
          }
        }
      },
    },
    measurement16: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_16 == null ||
          this.powdermodel_calibration_measures.measurement_16 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_16;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_16 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_16.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_16 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_16 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_16 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_16 = null;
          }
        }
      },
    },
    measurement17: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_17 == null ||
          this.powdermodel_calibration_measures.measurement_17 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_17;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_17 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_17.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_17 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_17 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_17 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_17 = null;
          }
        }
      },
    },
    measurement18: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_18 == null ||
          this.powdermodel_calibration_measures.measurement_18 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_18;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_18 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_18.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_18 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_18 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_18 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_18 = null;
          }
        }
      },
    },
    measurement19: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_19 == null ||
          this.powdermodel_calibration_measures.measurement_19 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_19;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_19 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_19.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_19 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_19 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_19 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_19 = null;
          }
        }
      },
    },
    measurement20: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_20 == null ||
          this.powdermodel_calibration_measures.measurement_20 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_20;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_20 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_20.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_20 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_20 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_20 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_20 = null;
          }
        }
      },
    },
    measurement21: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_21 == null ||
          this.powdermodel_calibration_measures.measurement_21 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_21;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_21 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_21.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_21 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_21 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_21 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_21 = null;
          }
        }
      },
    },
    measurement22: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_22 == null ||
          this.powdermodel_calibration_measures.measurement_22 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_22;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_22 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_22.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_22 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_22 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_22 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_22 = null;
          }
        }
      },
    },
    measurement23: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_23 == null ||
          this.powdermodel_calibration_measures.measurement_23 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_23;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_23 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_23.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_23 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_23 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_23 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_23 = null;
          }
        }
      },
    },
    measurement24: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_24 == null ||
          this.powdermodel_calibration_measures.measurement_24 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_24;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_24 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_24.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_24 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_24 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_24 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_24 = null;
          }
        }
      },
    },
    measurement25: {
      get() {
        if (
          this.powdermodel_calibration_measures.measurement_25 == null ||
          this.powdermodel_calibration_measures.measurement_25 == ""
        ) {
          return this.powdermodel_calibration_measures.measurement_25;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.measurement_25 * this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.measurement_25.toFixed(this.max_thickness_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.measurement_25 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.measurement_25 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.measurement_25 = value;
          } else {
            this.powdermodel_calibration_measures.measurement_25 = null;
          }
        }
      },
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    "$store.state.flightpath_pro_license"() {
      if (this.powdermodel_calibration_measures.powder_manufacturer === null) {
        if (this.$store.state.flightpath_pro_license) {
          this.powdermodel_calibration_measures.powder_manufacturer = "AkzoNobel";
        } else {
          this.powdermodel_calibration_measures.powder_manufacturer = null;
        }
      }
    },
  },
  mounted() {
    // this.populateMeasurementDefaults()
    this.getLine();
    setTooltip(this.$store.state.bootstrap);
    if (this.powdermodel_calibration_measures.powder_manufacturer === null) {
      if (this.$store.state.flightpath_pro_license) {
        this.powdermodel_calibration_measures.powder_manufacturer = "AkzoNobel";
      } else {
        this.powdermodel_calibration_measures.powder_manufacturer = null;
      }
    }
  },
  updated() {
    setTooltip(this.$store.state.bootstrap);
  },
  methods: {
    numericOnly,
    numericPositiveOnly,
    async getLine() {
      try {
        let response = await axios.get("/api/v1/fp/line/" + this.lineId);
        this.line = JSON.parse(JSON.stringify(response.data));
        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getLine();
          }.bind(this),
          5000,
        );
      }
    },
    postPowderModel() {
      if (!this.ProperlyFilledForm()) {
        return;
      }

      if (
        this.powdermodel_calibration_measures.air_parameter == "" ||
        this.powdermodel_calibration_measures.air_parameter == null
      ) {
        this.powdermodel_calibration_measures.air_parameter = 0;
      }
      if (
        this.powdermodel_calibration_measures.pistol_to_substrate_distance == "" ||
        this.powdermodel_calibration_measures.pistol_to_substrate_distance == null
      ) {
        this.powdermodel_calibration_measures.pistol_to_substrate_distance = 0;
      }
      if (this.powdermodel_calibration_measures.powder_amount == "") {
        this.powdermodel_calibration_measures.powder_amount = null;
      }

      this.calculation_in_progress = true;

      axios
        .post("api/v1/fp/powdercalibrationmeasures/", {
          name: this.powdermodel.name,
          line: this.lineId,
          calibration_type: this.powdermodel_calibration_measures.calibration_type,
          powder_amount: this.powdermodel_calibration_measures.powder_amount,
          powder_manufacturer: this.powdermodel_calibration_measures.powder_manufacturer,
          powder_article_id: this.powdermodel_calibration_measures.powder_article_id,
          batch_id: this.powdermodel_calibration_measures.batch_id,
          pump_operational_hours: this.powdermodel_calibration_measures.pump_operational_hours,
          nozzles_type: this.powdermodel_calibration_measures.nozzles_type,
          additional_info: this.powdermodel_calibration_measures.additional_info,
          booth_side: this.powdermodel_calibration_measures.booth_side,
          booth_temperature: this.powdermodel_calibration_measures.booth_temperature,
          booth_humidity: this.powdermodel_calibration_measures.booth_humidity,
          guns_spacing: this.powdermodel_calibration_measures.guns_spacing,
          calibration_line_speed: 0,
          max_pistol_to_substrate_distance_measurements:
            this.powdermodel_calibration_measures.pistol_to_substrate_distance / 100, // convert [cm] to [m]
          min_pistol_to_substrate_distance_measurements:
            this.powdermodel_calibration_measures.pistol_to_substrate_distance / 100, // convert [cm] to [m]
          air_parameter: this.powdermodel_calibration_measures.air_parameter,
          max_air_parameter_measurements: this.powdermodel_calibration_measures.air_parameter,
          min_air_parameter_measurements: this.powdermodel_calibration_measures.air_parameter,
          measurement_1: this.powdermodel_calibration_measures.measurement_1,
          measurement_2: this.powdermodel_calibration_measures.measurement_2,
          measurement_3: this.powdermodel_calibration_measures.measurement_3,
          measurement_4: this.powdermodel_calibration_measures.measurement_4,
          measurement_5: this.powdermodel_calibration_measures.measurement_5,
          measurement_6: this.powdermodel_calibration_measures.measurement_6,
          measurement_7: this.powdermodel_calibration_measures.measurement_7,
          measurement_8: this.powdermodel_calibration_measures.measurement_8,
          measurement_9: this.powdermodel_calibration_measures.measurement_9,
          measurement_10: this.powdermodel_calibration_measures.measurement_10,
          measurement_11: this.powdermodel_calibration_measures.measurement_11,
          measurement_12: this.powdermodel_calibration_measures.measurement_12,
          measurement_13: this.powdermodel_calibration_measures.measurement_13,
          measurement_14: this.powdermodel_calibration_measures.measurement_14,
          measurement_15: this.powdermodel_calibration_measures.measurement_15,
          measurement_16: this.powdermodel_calibration_measures.measurement_16,
          measurement_17: this.powdermodel_calibration_measures.measurement_17,
          measurement_18: this.powdermodel_calibration_measures.measurement_18,
          measurement_19: this.powdermodel_calibration_measures.measurement_19,
          measurement_20: this.powdermodel_calibration_measures.measurement_20,
          measurement_21: this.powdermodel_calibration_measures.measurement_21,
          measurement_22: this.powdermodel_calibration_measures.measurement_22,
          measurement_23: this.powdermodel_calibration_measures.measurement_23,
          measurement_24: this.powdermodel_calibration_measures.measurement_24,
          measurement_25: this.powdermodel_calibration_measures.measurement_25,
        })
        .then(response => {
          this.powdermodel.id = response.data.powdermodel;
          this.calculation_in_progress = false;

          this.$swal({
            title: `${this.$t("Powder Model created")}`,
            text: this.powdermodel.name + ` ${this.$t("was created successfully")}.`,
            icon: "success",
            confirmButtonText: "OK",
          });

          router.push({
            path: "/applications/flightpath/powdermodelslibrary/calibrated/" + this.powdermodel.id,
            replace: true,
          });
        })
        .catch(error => {
          this.$swal({
            title: this.$t("Error calibrating Powder Model. Please try again."),
            text: error.response.data,
            icon: "warning",
            confirmButtonText: "OK",
          });
          this.calculation_in_progress = false;
          console.error(error);
        });
    },
    ProperlyFilledForm() {
      let properly_filled = true;
      let missing_fields = [];
      if (this.powdermodel.name == null || this.powdermodel.name == "") {
        this.name_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Name")}`);
      }
      if (this.$store.state.flightpath_pro_license) {
        if (
          this.powdermodel_calibration_measures.nozzles_type == null ||
          this.powdermodel_calibration_measures.nozzles_type == ""
        ) {
          this.nozzles_type_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.$t("Nozzles Type")}`);
        }
        if (
          this.powdermodel_calibration_measures.batch_id == null ||
          this.powdermodel_calibration_measures.batch_id == ""
        ) {
          this.batch_id_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.$t("Batch ID")}`);
        }
        if (
          this.powdermodel_calibration_measures.powder_article_id == null ||
          this.powdermodel_calibration_measures.powder_article_id == ""
        ) {
          this.powder_article_id_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.powderLabel}`);
        }
        if (
          this.powdermodel_calibration_measures.powder_manufacturer == null ||
          this.powdermodel_calibration_measures.powder_manufacturer == ""
        ) {
          this.powder_manufacturer_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.$t("Powder Manufacturer")}`);
        }
      }
      if (
        this.powdermodel_calibration_measures.measurement_1 === null ||
        this.powdermodel_calibration_measures.measurement_1 === ""
      ) {
        this.measurement_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_2 === null ||
        this.powdermodel_calibration_measures.measurement_2 === ""
      ) {
        this.measurement_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_3 === null ||
        this.powdermodel_calibration_measures.measurement_3 === ""
      ) {
        this.measurement_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_4 === null ||
        this.powdermodel_calibration_measures.measurement_4 === ""
      ) {
        this.measurement_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_5 === null ||
        this.powdermodel_calibration_measures.measurement_5 === ""
      ) {
        this.measurement_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_6 === null ||
        this.powdermodel_calibration_measures.measurement_6 === ""
      ) {
        this.measurement_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_7 === null ||
        this.powdermodel_calibration_measures.measurement_7 === ""
      ) {
        this.measurement_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_8 === null ||
        this.powdermodel_calibration_measures.measurement_8 === ""
      ) {
        this.measurement_8_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 8`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_9 === null ||
        this.powdermodel_calibration_measures.measurement_9 === ""
      ) {
        this.measurement_9_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 9`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_10 === null ||
        this.powdermodel_calibration_measures.measurement_10 === ""
      ) {
        this.measurement_10_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 10`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_11 === null ||
        this.powdermodel_calibration_measures.measurement_11 === ""
      ) {
        this.measurement_11_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 11`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_12 === null ||
        this.powdermodel_calibration_measures.measurement_12 === ""
      ) {
        this.measurement_12_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 12`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_13 === null ||
        this.powdermodel_calibration_measures.measurement_13 === ""
      ) {
        this.measurement_13_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 13`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_14 === null ||
        this.powdermodel_calibration_measures.measurement_14 === ""
      ) {
        this.measurement_14_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 14`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_15 === null ||
        this.powdermodel_calibration_measures.measurement_15 === ""
      ) {
        this.measurement_15_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 15`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_16 === null ||
        this.powdermodel_calibration_measures.measurement_16 === ""
      ) {
        this.measurement_16_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 16`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_17 === null ||
        this.powdermodel_calibration_measures.measurement_17 === ""
      ) {
        this.measurement_17_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 17`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_18 === null ||
        this.powdermodel_calibration_measures.measurement_18 === ""
      ) {
        this.measurement_18_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 18`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_19 === null ||
        this.powdermodel_calibration_measures.measurement_19 === ""
      ) {
        this.measurement_19_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 19`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_20 === null ||
        this.powdermodel_calibration_measures.measurement_20 === ""
      ) {
        this.measurement_20_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 20`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_21 === null ||
        this.powdermodel_calibration_measures.measurement_21 === ""
      ) {
        this.measurement_21_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 21`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_22 === null ||
        this.powdermodel_calibration_measures.measurement_22 === ""
      ) {
        this.measurement_22_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 22`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_23 === null ||
        this.powdermodel_calibration_measures.measurement_23 === ""
      ) {
        this.measurement_23_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 23`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_24 === null ||
        this.powdermodel_calibration_measures.measurement_24 === ""
      ) {
        this.measurement_24_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 24`);
      }
      if (
        this.powdermodel_calibration_measures.measurement_25 === null ||
        this.powdermodel_calibration_measures.measurement_25 === ""
      ) {
        this.measurement_25_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Point")} 25`);
      }
      if (!properly_filled) {
        this.$swal({
          title: `${this.$t("Required Fields missing")}`,
          text: `${this.$t("Please fill the following fields")}: ` + missing_fields.join(", ") + ".",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      return properly_filled;
    },
    hideTooltip() {
      const elements = document.querySelectorAll(".tooltip");

      elements.forEach(element => {
        element.remove();
      });
    },
    populateMeasurementDefaults() {
      this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements = 0.34;
      this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements = 0.2;
      this.powdermodel_calibration_measures.max_air_parameter_measurements = 3;
      this.powdermodel_calibration_measures.min_air_parameter_measurements = 1.4;
      this.powdermodel_calibration_measures.batch_id = "akzoYY";
      this.powdermodel_calibration_measures.nozzles_type = "slit";
      this.powdermodel_calibration_measures.powder_article_id = "akzoidXX";

      this.powdermodel_calibration_measures.measurement_1 = 15;
      this.powdermodel_calibration_measures.measurement_2 = 20;
      this.powdermodel_calibration_measures.measurement_3 = 30;
      this.powdermodel_calibration_measures.measurement_4 = 20;
      this.powdermodel_calibration_measures.measurement_5 = 15;
      this.powdermodel_calibration_measures.measurement_6 = 20;
      this.powdermodel_calibration_measures.measurement_7 = 45;
      this.powdermodel_calibration_measures.measurement_8 = 60;
      this.powdermodel_calibration_measures.measurement_9 = 45;
      this.powdermodel_calibration_measures.measurement_10 = 20;
      this.powdermodel_calibration_measures.measurement_11 = 30;
      this.powdermodel_calibration_measures.measurement_12 = 75;
      this.powdermodel_calibration_measures.measurement_13 = 85;
      this.powdermodel_calibration_measures.measurement_14 = 60;
      this.powdermodel_calibration_measures.measurement_15 = 30;
      this.powdermodel_calibration_measures.measurement_16 = 20;
      this.powdermodel_calibration_measures.measurement_17 = 75;
      this.powdermodel_calibration_measures.measurement_18 = 85;
      this.powdermodel_calibration_measures.measurement_19 = 60;
      this.powdermodel_calibration_measures.measurement_20 = 20;
      this.powdermodel_calibration_measures.measurement_21 = 15;
      this.powdermodel_calibration_measures.measurement_22 = 20;
      this.powdermodel_calibration_measures.measurement_23 = 30;
      this.powdermodel_calibration_measures.measurement_24 = 20;
      this.powdermodel_calibration_measures.measurement_25 = 15;
    },
  },
};
</script>

<style scoped>
.circle {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background: #fff;
  border: 3px solid #000;
  margin-left: auto;
  margin-right: auto;
  color: #000;
  text-align: center;
  align-items: center;
  font: 32px Arial, sans-serif;
  z-index: 1;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 20px 20px;
  width: 960px;
}
.cell,
.topcell,
.bottomcell,
.rightcell,
.leftcell,
.topleftcell,
.bottomleftcell,
.toprightcell,
.bottomrightcell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cell::before,
.cell::after,
.topcell::before,
.topcell::after,
.bottomcell::before,
.bottomcell::after,
.rightcell::before,
.rightcell::after,
.leftcell::before,
.leftcell::after,
.topleftcell::before,
.topleftcell::after,
.bottomleftcell::before,
.bottomleftcell::after,
.toprightcell::before,
.toprightcell::after,
.bottomrightcell::before,
.bottomrightcell::after {
  content: "";
  position: absolute;
  background: #000;
}

.cell::before,
.topcell::before,
.bottomcell::before,
.rightcell::before,
.leftcell::before,
.topleftcell::before,
.bottomleftcell::before,
.toprightcell::before,
.bottomrightcell::before {
  top: 50%;
  left: -10px;
  right: -10px;
  height: 3px;
}

.cell::after,
.leftcell::after,
.rightcell::after {
  left: 50%;
  top: -10px;
  bottom: -40px;
  width: 3px;
}

.topcell::after,
.topleftcell::after,
.toprightcell::after {
  left: 50%;
  top: 50%;
  bottom: -40px;
  width: 3px;
}

.bottomcell::after,
.bottomleftcell::after,
.bottomrightcell::after {
  left: 50%;
  top: -10px;
  bottom: 50%;
  width: 3px;
}

.rightcell::before,
.toprightcell::before,
.bottomrightcell::before {
  top: 50%;
  left: -10px;
  right: 50%;
  height: 3px;
}

.leftcell::before,
.topleftcell::before,
.bottomleftcell::before {
  top: 50%;
  left: 50%;
  right: -10px;
  height: 3px;
}
</style>

<i18n>
  {
    "EN": {
        "Error calibrating Powder Model. Please try again.": "Error calibrating Powder Model. Please try again.",
        "New static calibration of a Powder Model for":"New static calibration of a Powder Model for",
        "Name":"Name",
        "Gun to substrate distance": "Gun to substrate distance",
        "Powder model name": "Powder model name",
        "Optional Parameters": "Optional Parameters",
        "Air parameter": "Air parameter",
        "Booth side used for calibration": "Booth side used for calibration",
        "right": "right",
        "left": "left",
        "Nozzle Type": "Nozzle Type",
        "Powder Amount": "Powder Amount",
        "Powder Manufacturer": "Powder Manufacturer",
        "Powder ID": "Powder ID",
        "Additional Info": "Additional Info",
        "Measurements": "Measurements",
        "Point": "Point",
        "Fitting PowderModel Distribution from measurements": "Fitting PowderModel Distribution from measurements",
        "Create Powder Distribution Model": "Create Powder Distribution Model",
        "Powder Model created": "Powder Model created",
        "was created successfully": "was created successfully",
        "Required Fields missing": "Required Fields missing",
        "Please fill the following fields": "Please fill the following fields",
        "PowderModel was not created. Try again.": "PowderModel was not created. Try again.",
        "Calibration Plate Measurements": "Calibration Plate Measurements",
        "evenly spaced": "evenly spaced",
        "unevenly spaced": "unevenly spaced",
        "Booth Humidity": "Booth Humidity",
        "Booth Temperature": "Booth Temperature",
        "Pump Operational Hours": "Pump Operational Hours",
        "Batch ID": "Batch ID",
        "Guns Spacing": "Guns Spacing",
        "Other": "Other",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Airflow or air volume selected in the powder coating machine",
        "Quantity of powder dispensed by the gun per unit time": "Quantity of powder dispensed by the gun per unit time",
        "Operational Hours Since Last Maintanance": "Operational Hours Since Last Maintanance",
        "Operational Hours Injector": "Operational Hours Injector",
    },
    "DE": {
        "Error calibrating Powder Model. Please try again.": "Fehler bei der Kalibrierung des Powder Model. Bitte versuchen Sie es erneut.",
        "New static calibration of a Powder Model for":"Neue statische Kalibrierung eines Pulvermodells für",
        "Name":"Name",
        "Gun to substrate distance": "Abstand zwischen Gune und Substrat",
        "Powder model name": "Name des Sprühbild",
        "Optional Parameters": "Optionale Parameter",
        "Air parameter": "Luftparameter",
        "Booth side used for calibration": "Kabinenseite für die Kalibrierung",
        "right": "rechts",
        "left": "links",
        "Nozzle Type": "Düsentyp",
        "Powder Amount": "Pulvermenge",
        "Powder Manufacturer": "Pulverhersteller",
        "Powder ID": "Pulver ID",
        "Additional Info": "Zusätzliche Informationen",
        "Measurements": "Messungen",
        "Point": "Punkt",
        "Fitting PowderModel Distribution from measurements": "Anpassung der Pulvermodellverteilung anhand von Messungen",
        "Create Powder Distribution Model": "Erstellen des Sprühbilds",
        "Powder Model created": "Pulvermodell erstellt",
        "was created successfully": "wurde erfolgreich erstellt",
        "Required Fields missing": "Erforderliche Felder fehlen",
        "Please fill the following fields": "Bitte füllen Sie die folgenden Felder aus",
        "PowderModel was not created. Try again.": "Sprühbild wurde nicht erstellt. Versuchen Sie es erneut.",
        "Calibration Plate Measurements": "Kalibrierplattenmessungen",
        "evenly spaced": "gleichmäßig verteilt",
        "unevenly spaced": "ungleichmäßig verteilt",
        "Booth Humidity": "Kabinenfeuchtigkeit",
        "Booth Temperature": "Kabinentemperatur",
        "Pump Operational Hours": "Betriebsstunden der Pumpe",
        "Batch ID": "Chargen-ID",
        "Guns Spacing": "Pistolenabstand",
        "Other": "Andere",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Luftstrom oder Luftvolumen, das in der Pulverbeschichtungsmaschine ausgewählt wurde",
        "Quantity of powder dispensed by the gun per unit time": "Menge an Pulver, die pro Zeiteinheit von der Pistole abgegeben wird",
        "Operational Hours Since Last Maintanance": "Betriebsstunden seit der letzten Wartung",
        "Operational Hours Injector": "Betriebsstunden Injektor",
    },
    "ES": {
        "Error calibrating Powder Model. Please try again.": "Error al calibrar el modelo de polvo. Por favor, inténtelo de nuevo.",
        "New static calibration of a Powder Model for":"Nueva calibración estática de un modelo de polvo para",
        "Name":"Nombre",
        "Gun to substrate distance": "Distancia de la pistola al sustrato",
        "Powder model name": "Nombre del modelo de pintura en polvo",
        "Optional Parameters": "Parámetros opcionales",
        "Air parameter": "Parámetro de flujo aire",
        "Booth side used for calibration": "Lado de la cabina utilizado para la calibración",
        "right": "derecho",
        "left": "izquierdo",
        "Nozzle Type": "Tipo de boquilla",
        "Powder Amount": "Cantidad de pintura en polvo",
        "Powder Manufacturer": "Fabricante de pintura en polvo",
        "Powder ID": "ID de pintura en polvo",
        "Additional Info": "Información adicional",
        "Measurements": "Mediciones",
        "Point": "Punto",
        "Fitting PowderModel Distribution from measurements": "Estimación del modelo a partir de las mediciones",
        "Create Powder Distribution Model": "Crear modelo de distribución de polvo",
        "Powder Model created": "Modelo de pintura en polvo creado",
        "was created successfully": "se creó con éxito",
        "Required Fields missing": "Faltan campos obligatorios",
        "Please fill the following fields": "Por favor, rellene los siguientes campos",
        "PowderModel was not created. Try again.": "El modelo de pintura en polvo no se creó. Inténtalo de nuevo.",
        "Calibration Plate Measurements": "Mediciones de la placa de calibración",
        "evenly spaced": "equidistantes",
        "unevenly spaced": "no equidistantes",
        "Booth Humidity": "Humedad de la cabina",
        "Booth Temperature": "Temperatura de la cabina",
        "Pump Operational Hours": "Horas de funcionamiento de la bomba",
        "Batch ID": "ID del lote",
        "Guns Spacing": "Espaciado de las pistolas",
        "Other": "Otro",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Flujo de aire o volumen de aire seleccionado en la máquina de recubrimiento en polvo",
        "Quantity of powder dispensed by the gun per unit time": "Cantidad de pintura en polvo dispensada por la pistola por unidad de tiempo",
        "Operational Hours Since Last Maintanance": "Horas de funcionamiento desde el último mantenimiento",
        "Operational Hours Injector": "Horas de funcionamiento del inyector",
    },
    "FR": {
        "Error calibrating Powder Model. Please try again.": "Erreur d'étalonnage du modèle de poudre. Veuillez réessayer.",
        "New static calibration of a Powder Model for":"Nouvel étalonnage statique d'un modèle de poudre pour",
        "Name":"Nom",
        "Gun to substrate distance": "Distance entre le pistolet et le substrat",
        "Powder model name": "Nom du modèle de poudre",
        "Optional Parameters": "Paramètres optionnels",
        "Air parameter": "Paramètre d'air",
        "Booth side used for calibration": "Côté de la cabine utilisé pour l'étalonnage",
        "right": "droite",
        "left": "gauche",
        "Nozzle Type": "Type de buse",
        "Powder Amount": "Quantité de poudre",
        "Powder Manufacturer": "Fabricant de poudre",
        "Powder ID": "Poudre ID",
        "Additional Info": "Informations supplémentaires",
        "Measurements": "Mesures",
        "Point": "Point",
        "Fitting PowderModel Distribution from measurements": "Ajustement de la distribution du modèle de poudre à partir des mesures",
        "Create Powder Distribution Model": "Créer un modèle de distribution de poudre",
        "Powder Model created": "Modèle de poudre créé",
        "was created successfully": "a été créé avec succès",
        "Required Fields missing": "Champs obligatoires manquants",
        "Please fill the following fields": "Veuillez remplir les champs suivants",
        "PowderModel was not created. Try again.": "Le modèle de poudre n'a pas été créé. Réessayer.",
        "Calibration Plate Measurements": "Mesures de la plaque de calibrage",
        "evenly spaced": "uniformément espacés",
        "unevenly spaced": "espacés de manière inégale",
        "Booth Humidity": "Humidité de la cabine",
        "Booth Temperature": "Température de la cabine",
        "Pump Operational Hours": "Heures de fonctionnement de la pompe",
        "Batch ID": "ID du lot",
        "Guns Spacing": "Espacement des pistolets",
        "Other": "Autre",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Débit d'air ou volume d'air sélectionné dans la machine de revêtement en poudre",
        "Quantity of powder dispensed by the gun per unit time": "Quantité de poudre distribuée par le pistolet par unité de temps",
        "Operational Hours Since Last Maintanance": "Heures de fonctionnement depuis la dernière maintenance",
        "Operational Hours Injector": "Heures de fonctionnement de l'injecteur",
    },
    "IT": {
        "Error calibrating Powder Model. Please try again.": "Errore nella calibrazione del modello Powder. Riprovare.",
        "New static calibration of a Powder Model for":"Nuova calibrazione statica di un modello di polvere per",
        "Name":"Nome",
        "Gun to substrate distance": "Distanza pistola-substrato",
        "Powder model name": "Nome del modello di polvere",
        "Optional Parameters": "Parametri opzionali",
        "Air parameter": "Parametro dell'aria",
        "Booth side used for calibration": "Lato cabina utilizzato per la calibrazione",
        "right": "destra",
        "left": "sinistra",
        "Nozzle Type": "Tipo di ugello",
        "Powder Amount": "Quantità di polvere",
        "Powder Manufacturer": "Produttore di polvere",
        "Powder ID": "Polvere ID",
        "Additional Info": "Informazioni aggiuntive",
        "Measurements": "Misure",
        "Point": "Punto",
        "Fitting PowderModel Distribution from measurements": "Adattamento della distribuzione del modello di polvere dalle misurazioni",
        "Create Powder Distribution Model": "Crea modello di distribuzione di polvere",
        "Powder Model created": "Modello di polvere creato",
        "was created successfully": "è stato creato con successo",
        "Required Fields missing": "Campi obbligatori mancanti",
        "Please fill the following fields": "Si prega di compilare i seguenti campi",
        "PowderModel was not created. Try again.": "Il modello di polvere non è stato creato. Riprova.",
        "Calibration Plate Measurements": "Misure della piastra di calibrazione",
        "evenly spaced": "uniformemente spaziati",
        "unevenly spaced": "spaziati in modo non uniforme",
        "Booth Humidity": "Umidità della cabina",
        "Booth Temperature": "Temperatura della cabina",
        "Pump Operational Hours": "Ore operative della pompa",
        "Batch ID": "ID batch",
        "Guns Spacing": "Spaziatura delle pistole",
        "Other": "Altro",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Flusso d'aria o volume d'aria selezionato nella macchina di verniciatura a polvere",
        "Quantity of powder dispensed by the gun per unit time": "Quantità di polvere erogata dalla pistola per unità di tempo",
        "Operational Hours Since Last Maintanance": "Ore operative dall'ultima manutenzione",
        "Operational Hours Injector": "Ore operative dell'iniettore",
    },
    "PL": {
        "Error calibrating Powder Model. Please try again.": "Błąd kalibracji modelu proszku. Spróbuj ponownie.",
        "New static calibration of a Powder Model for":"Nowa statyczna kalibracja modelu proszkowego dla",
        "Name":"Nazwa",
        "Gun to substrate distance": "Odległość pistoletu od podłoża",
        "Powder model name": "Nazwa modelu proszkowego",
        "Optional Parameters": "Parametry opcjonalne",
        "Air parameter": "Parametr powietrza",
        "Booth side used for calibration": "Strona kabiny użyta do kalibracji",
        "right": "prawo",
        "left": "lewo",
        "Nozzle Type": "Typ dyszy",
        "Powder Amount": "Ilość proszku",
        "Powder Manufacturer": "Producent proszku",
        "Powder ID": "Proszku ID",
        "Additional Info": "Dodatkowe informacje",
        "Measurements": "Pomiary",
        "Point": "Punkt",
        "Fitting PowderModel Distribution from measurements": "Dopasowanie rozkładu modelu proszkowego z pomiarów",
        "Create Powder Distribution Model": "Utwórz model dystrybucji proszku",
        "Powder Model created": "Model proszku utworzony",
        "was created successfully": "został utworzony pomyślnie",
        "Required Fields missing": "Brakujące wymagane pola",
        "Please fill the following fields": "Proszę wypełnić następujące pola",
        "PowderModel was not created. Try again.": "Model proszku nie został utworzony. Spróbuj ponownie.",
        "Calibration Plate Measurements": "Pomiary płyty kalibracyjnej",
        "evenly spaced": "równomiernie rozłożone",
        "unevenly spaced": "unevenly spaced",
        "Booth Humidity": "Wilgotność kabiny",
        "Booth Temperature": "Temperatura kabiny",
        "Pump Operational Hours": "Godziny pracy pompy",
        "Batch ID": "ID partii",
        "Guns Spacing": "Rozstawienie pistoletów",
        "Other": "Inne",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Przepływ powietrza lub objętość powietrza wybrana w maszynie do malowania proszkowego",
        "Quantity of powder dispensed by the gun per unit time": "Ilość proszku wydawana przez pistolet na jednostkę czasu",
        "Operational Hours Since Last Maintanance": "Godziny pracy od ostatniego przeglądu",
        "Operational Hours Injector": "Godziny pracy wtryskiwacza",
    },
    "TR": {
        "Error calibrating Powder Model. Please try again.": "Toz Modeli kalibre edilirken hata oluştu. Lütfen tekrar deneyin.",
        "New static calibration of a Powder Model for":"Toz Modeli için yeni statik kalibrasyon",
        "Name":"İsim",
        "Gun to substrate distance": "Tabancadan alt tabakaya mesafe",
        "Powder model name": "Toz modeli adı",
        "Optional Parameters": "İsteğe bağlı parametreler",
        "Air parameter": "Hava parametresi",
        "Booth side used for calibration": "Kalibrasyon için kullanılan kabin tarafı",
        "right": "sağ",
        "left": "sol",
        "Nozzle Type": "Nozul tipi",
        "Powder Amount": "Toz miktarı",
        "Powder Manufacturer": "Toz üreticisi",
        "Powder ID": "Toz ID",
        "Additional Info": "Ek bilgi",
        "Measurements": "Ölçümler",
        "Point": "Nokta",
        "Fitting PowderModel Distribution from measurements": "Ölçümlerden Toz Modeli Dağılımı Uyumu",
        "Create Powder Distribution Model": "Toz Dağıtım Modeli Oluştur",
        "Powder Model created": "Toz Modeli oluşturuldu",
        "was created successfully": "başarıyla oluşturuldu",
        "Required Fields missing": "Gerekli Alanlar eksik",
        "Please fill the following fields": "Lütfen aşağıdaki alanları doldurun",
        "PowderModel was not created. Try again.": "Toz Modeli oluşturulamadı. Tekrar deneyin.",
        "Calibration Plate Measurements": "Kalibrasyon Plakası Ölçümleri",
        "evenly spaced": "eşit aralıklı",
        "unevenly spaced": "eşit olmayan aralıklı",
        "Booth Humidity": "Kabin Nem",
        "Booth Temperature": "Kabin Sıcaklığı",
        "Pump Operational Hours": "Pompa Çalışma Saatleri",
        "Batch ID": "Toplu Kimlik",
        "Guns Spacing": "Tabancaların aralığı",
        "Other": "Diğer",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Toz kaplama makinesinde seçilen hava akışı veya hava hacmi",
        "Quantity of powder dispensed by the gun per unit time": "Tabanca tarafından birim zamanda verilen toz miktarı",
        "Operational Hours Since Last Maintanance": "Son Bakımdan Bu Yana Çalışma Saatleri",
        "Operational Hours Injector": "Enjektör Çalışma Saatleri",
    },
    "ZH": {
        "Error calibrating Powder Model. Please try again.": "粉末模型校准出错。请重试。",
        "New static calibration of a Powder Model for":"粉末模型的新静态校准",
        "Name":"名称",
        "Gun to substrate distance": "手枪到基板的距离",
        "Powder model name": "粉末模型名称",
        "Optional Parameters": "可选参数",
        "Air parameter": "空气参数",
        "Booth side used for calibration": "用于校准的展位侧",
        "right": "对",
        "left": "左",
        "Nozzle Type": "喷嘴类型",
        "Powder Amount": "粉末量",
        "Powder Manufacturer": "粉末制造商",
        "Powder ID": "粉末ID",
        "Additional Info": "额外信息",
        "Measurements": "测量",
        "Point": "点",
        "Fitting PowderModel Distribution from measurements": "从测量中拟合粉末模型分布",
        "Create Powder Distribution Model": "创建粉末分布模型",
        "Powder Model created": "粉末模型已创建",
        "was created successfully": "已成功创建",
        "Required Fields missing": "缺少必填字段",
        "Please fill the following fields": "请填写以下字段",
        "PowderModel was not created. Try again.": "未创建粉末模型。请重试。",
        "Calibration Plate Measurements": "校准板测量",
        "evenly spaced": "均匀间隔",
        "unevenly spaced": "不均匀间隔",
        "Booth Humidity": "展位湿度",
        "Booth Temperature": "展位温度",
        "Pump Operational Hours": "泵操作小时",
        "Batch ID": "批次ID",
        "Guns Spacing": "枪支间距",
        "Other": "其他",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "粉末涂层机中选择的气流或空气体积",
        "Quantity of powder dispensed by the gun per unit time": "枪每单位时间分配的粉末量",
        "Operational Hours Since Last Maintanance": "上次维护以来的运行时间",
        "Operational Hours Injector": "喷油嘴运行小时",
    },
  }
</i18n>
