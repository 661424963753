<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-250 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 30%">
          <h3 style="color: rgb(255, 255, 255); text-align: center">{{ $t("Powder Models Library") }}</h3>
          <h5 style="color: rgb(255, 255, 255); text-align: center">{{ line.name }}</h5>
        </div>
      </div>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="row mt-5 text-center">
          <div class="col-4 text-center">
            <button
              class="btn py-4"
              style="width: 200px; background-color: rgb(241, 241, 241)"
              @click="$router.push({ name: 'PowderModelAdd', params: { lineId: lineId } })"
            >
              {{ $t("New Powder Calibration") }}
            </button>
            <div class="text-xs text-center text-muted">{{ $t("Estimated 10 mins") }}</div>
          </div>
          <div class="col-4 text-center">
            <div v-if="showAdvancedCalibrationButton">
              <button
                class="btn py-4"
                style="width: 200px; background-color: rgb(241, 241, 241)"
                @click="$router.push({ name: 'AdvancedPowderModelAdd', params: { lineId: lineId } })"
              >
                {{ $t("New Advanced Powder Calibration") }}
              </button>
              <div class="text-xs text-center text-muted">{{ $t("Estimated 30 mins") }}</div>
            </div>
          </div>
          <div class="col-4 text-center">
            <button
              class="btn py-4"
              style="width: 200px; background-color: rgb(241, 241, 241)"
              @click="$router.push({ name: 'StaticPowderModelAdd', params: { lineId: lineId } })"
            >
              {{ $t("New Static Powder Calibration") }}
            </button>
            <div class="text-xs text-center text-muted">{{ $t("Estimated 6 mins") }}</div>
          </div>
        </div>
        <div class="row mx-4 mt-6">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="showAdvancedCalibrationButton"
              :disabled="false"
              @change="showAdvancedCalibrationButton = !showAdvancedCalibrationButton"
            />
            <label
              class="form-check-label ms-1"
            >
            {{ $t("Show advanced powder model calibration options") }}
            </label>
          </div>
        </div>
        <div class="row">
          <PowderModelList
            :line-name="line.name"
            :line-id="lineId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PowderModelList from "./components/PowderModelList.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { useGetLine } from "@/views/composables.js";


export default {
  name: "PowderModelsLibrary",
  components: {
    PowderModelList,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    return { line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        showMenu: false,
        showAdvancedCalibrationButton: false,
      };
    }
  },
  computed: {},
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getData () {
      await this.getLine(this.lineId);
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    "Powder Models Library":"Powder Models Library",
    "Create Powder Model":"Create Powder Model",
    "New Powder Calibration":"New Powder Calibration",
    "New Advanced Powder Calibration":"New Advanced Powder Calibration",
    "New Static Powder Calibration":"New Static Powder Calibration",
    "Estimated 10 mins":"Estimated 10 mins",
    "Estimated 30 mins":"Estimated 30 mins",
    "Estimated 6 mins":"Estimated 6 mins",
    "Show advanced powder model calibration options": "Show advanced powder model calibration options",
    },
    "DE": {
    "Powder Models Library":"Bibliothek der Sprühbilder",
    "Create Powder Model":"Pulver-Modell Erstellen",
    "New Powder Calibration":"Neue Pulver-Kalibrierung",
    "New Advanced Powder Calibration":"Neue Erweiterte Pulver-Kalibrierung",
    "New Static Powder Calibration":"Neue statische Pulver-Kalibrierung",
    "Estimated 10 mins":"Geschätzte 10 Min",
    "Estimated 30 mins":"Geschätzte 30 Min",
    "Estimated 6 mins":"Geschätzte 6 Min",
    "Show advanced powder model calibration options": "Erweiterte Optionen zur Kalibrierung des Pulvermodells anzeigen",
    },
    "ES": {
    "Powder Models Library":"Lista de Modelos de Polvo",
    "Create Powder Model":"Crear Modelo de Polvo",
    "New Powder Calibration":"Nueva Calibración de Modelo de Polvo",
    "New Advanced Powder Calibration":"Nueva Calibración Avanzada de Modelo de Polvo",
    "New Static Powder Calibration":"Nueva Calibración Estática de Modelo de Polvo",
    "Estimated 10 mins":"Tiempo Estimado: 10 min",
    "Estimated 30 mins":"Tiempo Estimado: 30 min",
    "Estimated 6 mins":"Tiempo Estimado: 6 min",
    "Show advanced powder model calibration options": "Mostrar opciones de calibración avanzadas",
    },
    "FR": {
    "Powder Models Library":"Bibliothèque de Modèles de Poudres",
    "Create Powder Model":"Créer Modèle de Poudre",
    "New Powder Calibration":"Nouveau Calibrage de la Poudre",
    "New Advanced Powder Calibration":"Nouveau Calibrage Statique des Poudres",
    "New Static Powder Calibration":"New Static Powder Calibration",
    "Estimated 10 mins":"Estimation de 10 min",
    "Estimated 30 mins":"Estimation de 30 min",
    "Estimated 6 mins":"Estimation de 6 min",
    "Show advanced powder model calibration options": "Afficher les options avancées d'étalonnage du modèle de poudre",
    },
    "IT": {
    "Powder Models Library":"Libreria dei Modelli di Polvere",
    "Create Powder Model":"Creare Modello di Polvere",
    "New Powder Calibration":"Nuova Calibrazione del Modello di Polvere",
    "New Advanced Powder Calibration":"Nuova Calibrazione Avanzata del Modello di Polvere",
    "New Static Powder Calibration":"Nuova Calibrazione Statica del Modello di Polvere",
    "Estimated 10 mins":"Tempo Stimato 10 min",
    "Estimated 30 mins":"Tempo Stimato 30 min",
    "Estimated 6 mins":"Tempo Stimato 6 min",
    "Show advanced powder model calibration options": "Mostra le opzioni avanzate di calibrazione del modello di polvere",
    },
    "PL": {
    "Powder Models Library":"Biblioteka Modeli Proszkowych",
    "Create Powder Model":"Utwórz Model Proszku",
    "New Powder Calibration":"Nowa Kalibracja Proszku",
    "New Advanced Powder Calibration":"Nowa Zaawansowana Kalibracja Proszku",
    "New Static Powder Calibration":"Nowa Statyczna Kalibracja Proszku",
    "Estimated 10 mins":"Szacunkowo 10 min",
    "Estimated 30 mins":"Szacunkowo 30 min",
    "Estimated 6 mins":"Szacunkowo 6 min",
    "Show advanced powder model calibration options": "Pokaż zaawansowane opcje kalibracji modelu proszku",
    },
    "TR": {
    "Powder Models Library":"Toz Modelleri Kütüphanesi",
    "Create Powder Model":"Toz Modeli Oluşturun",
    "New Powder Calibration":"Yeni Toz Kalibrasyonu",
    "New Advanced Powder Calibration":"Yeni Gelişmiş Toz Kalibrasyonu",
    "New Static Powder Calibration":"Yeni Statik Toz Kalibrasyonu",
    "Estimated 10 mins":"Tahmini 10 dakika",
    "Estimated 30 mins":"Tahmini 30 dakika",
    "Estimated 6 mins":"Tahmini 6 dakika",
    "Show advanced powder model calibration options": "Gelişmiş toz modeli kalibrasyon seçeneklerini göster",
    },
    "ZH": {
    "Powder Models Library":"粉末模型库",
    "Create Powder Model":"创建粉末模型",
    "New Powder Calibration":"新粉末校准",
    "New Advanced Powder Calibration":"新型高级粉末校准",
    "New Static Powder Calibration":"新型静态粉末校准",
    "Estimated 10 mins":"预计 10 分钟",
    "Estimated 30 mins":"预计 30 分钟",
    "Estimated 6 mins":"预计 6 分钟",
    "Show advanced powder model calibration options": "显示高级粉末模型校准选项",
    }
  }
</i18n>
