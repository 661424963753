<template>
  <main class="mt-0 main-content main-content-bg">
    <div class="mt-4 mx-4">
      <a class="text-bold">{{ $t("Language") }}:&nbsp;</a>
        <select
          id="language-choices-category"
          v-model="this.$i18n.locale"
          name="language-choices"
          class="bg-transparent border-0 text-black"
          @change="changeLanguage()"
        >
        <option
          v-for="(language_option, key) in languages"
          :key="key"
          :value="key"
          class="text-dark"
        >
          <span class="d-sm-inline d-none"> {{ language_option.emoji }} {{ key }} - {{ language_option.name }} </span>
        </option>
      </select>
    </div>
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="px-5 col-xl-5 col-lg-6 col-md-8 col-12 d-flex flex-column">
              <div class="mt-4 card card-body text-left">
                <h3 class="text-info text-gradient">{{ $t("Reset Password") }}</h3>
                <p
                  v-if="!emailSent"
                  class="mb-2"
                >
                  {{ $t("Please enter your email address in the field below.") }}
                </p>
                <div class="mb-4 mt-2">
                  <form
                    v-if="!emailSent"
                    role="form"
                  >
                    <label>{{ $t("Email") }}</label
                    >&nbsp;
                    <div class="mb-3">
                      <input
                        v-model="email"
                        type="email"
                        :class="email_input_error ? `form-control is-invalid` : `form-control`"
                        :placeholder="$t('Enter your e-mail')"
                        @input="
                          email_input_error = false;
                          email = email.toLowerCase();
                        "
                        @keypress.enter.prevent="ResetPassword();"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        class="w-100 mt-2 mb-0 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0"
                        type="button"
                        @click="ResetPassword();"
                      >
                        {{ $t("Reset Password") }}
                      </button>
                    </div>
                  </form>
                  <div v-else>
                    {{
                      $t("We will send a link to your email to reset your password. Please check your inbox shortly.")
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { validateEmail } from "@/views/composables.js";

export default {
  name: "ResetPasswordByEmail",
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      emailSent: false,
      email_input_error: false,
      email: "",
      languages: {
        EN: { name: "English", emoji: "🇬🇧" },
        DE: { name: "Deutsch", emoji: "🇩🇪" },
        ES: { name: "Español", emoji: "🇪🇸" },
        FR: { name: "Français", emoji: "🇫🇷" },
        IT: { name: "Italiano", emoji: "🇮🇹" },
        PL: { name: "Polski", emoji: "🇵🇱" },
        TR: { name: "Türkçe", emoji: "🇹🇷" },
        ZH: { name: "中文", emoji: "🇨🇳" },
      },
    };
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();

  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    validateEmail,
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async ResetPassword() {
      if (this.email === "" || !this.validateEmail(this.email)) {
        this.email_input_error = true;
        this.$swal({
          title: `${this.$t("Invalid email format.")}`,
          text: `${this.$t("Please enter a valid email, with the format")} name@email.com`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      try {
        const respose = await axios.get("/api/v1/useremailexists/", {
          params: {
            email: this.email,
          },
        });

        if (!respose.data.email_exists) {
          this.email_input_error = true;
          this.$swal({
            title: `${this.$t("User not found")}`,
            text: `${this.$t("No user exists with the provided email.")}`,
            icon: "warning",
            confirmButtonText: "OK",
          });
          return;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        await axios.post("/api/v1/users/reset_password/", {
          email: this.email,
        });
        this.emailSent = true;
      } catch (error) {
        console.error(error);
        this.email_input_error = true;
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
      "Email": "Email",
      "Enter your e-mail": "Enter your e-mail",
      "Reset Password": "Reset Password",
      "Email field is empty.": "Email field is empty.",
      "Send Email": "Send Email",
      "Please enter your email address in the field below.": "Please enter your email address in the field below.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "We will send a link to your email to reset your password. Please check your inbox shortly.",
      "Invalid email format.": "Invalid email format.",
      "Please enter a valid email, with the format": "Please enter a valid email, with the format",
      "No user exists with the provided email.": "No user exists with the provided email.",
      "User not found": "User not found",
    },
    "DE": {
      "Email": "E-Mail",
      "Enter your e-mail": "Geben Sie Ihre E-Mail-Adresse ein",
      "Reset Password": "Passwort zurücksetzen",
      "Email field is empty.": "Das E-Mail-Feld ist leer.",
      "Send Email": "E-Mail senden",
      "Please enter your email address in the field below.": "Bitte geben Sie Ihre E-Mail-Adresse in das untenstehende Feld ein.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "Wir senden Ihnen einen Link an Ihre E-Mail-Adresse, um Ihr Passwort zurückzusetzen. Bitte überprüfen Sie in Kürze Ihren Posteingang.",
      "Invalid email format.": "Ungültiges E-Mail-Format.",
      "Please enter a valid email, with the format": "Bitte geben Sie eine gültige E-Mail-Adresse im Format ein",
      "No user exists with the provided email.": "Es existiert kein Benutzer mit der angegebenen E-Mail-Adresse.",
      "User not found": "Benutzer nicht gefunden",
    },
    "ES": {
      "Email": "Email",
      "Enter your e-mail": "Introduzca su correo electrónico",
      "Reset Password": "Restablecer la contraseña",
      "Email field is empty.": "El campo de correo electrónico está vacío.",
      "Send Email": "Enviar correo electrónico",
      "Please enter your email address in the field below.": "Por favor, introduzca su dirección de correo electrónico en el campo a continuación.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "Le enviaremos un enlace a su correo electrónico para restablecer su contraseña. Por favor, revise su bandeja de entrada en breve.",
      "Invalid email format.": "Formato de correo electrónico no válido.",
      "Please enter a valid email, with the format": "Por favor, introduzca un correo electrónico válido, con el formato",
      "No user exists with the provided email.": "No existe ningún usuario con el correo electrónico proporcionado.",
      "User not found": "Usuario no encontrado",
    },
    "FR": {
      "Email": "Email",
      "Enter your e-mail": "Entrez votre e-mail",
      "Reset Password": "Réinitialiser le mot de passe",
      "Email field is empty.": "Le champ E-mail est vide.",
      "Send Email": "Envoyer l'e-mail",
      "Please enter your email address in the field below.": "Veuillez saisir votre adresse e-mail dans le champ ci-dessous.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "Nous vous enverrons un lien à votre adresse e-mail pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception sous peu.",
      "Invalid email format.": "Format d'e-mail invalide.",
      "Please enter a valid email, with the format": "Veuillez saisir un e-mail valide, avec le format",
      "No user exists with the provided email.": "Aucun utilisateur n'existe avec l'e-mail fourni.",
      "User not found": "Utilisateur non trouvé",
    },
    "IT": {
      "Email": "Email",
      "Enter your e-mail": "Inserisci la tua email",
      "Reset Password": "Reimposta la password",
      "Email field is empty.": "Il campo Email è vuoto.",
      "Send Email": "Invia email",
      "Please enter your email address in the field below.": "Inserisci il tuo indirizzo email nel campo sottostante.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "Ti invieremo un link alla tua email per reimpostare la tua password. Controlla la tua casella di posta in arrivo a breve.",
      "Invalid email format.": "Formato email non valido.",
      "Please enter a valid email, with the format": "Inserisci un'email valida, con il formato",
      "No user exists with the provided email.": "Nessun utente esiste con l'email fornita.",
      "User not found": "Utente non trovato",
    },
    "PL": {
      "Email": "E-mail",
      "Enter your e-mail": "Wprowadź swój adres e-mail",
      "Reset Password": "Zresetuj hasło",
      "Email field is empty.": "Pole E-mail jest puste.",
      "Send Email": "Wyślij e-mail",
      "Please enter your email address in the field below.": "Proszę wprowadzić swój adres e-mail w polu poniżej.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "Wyślemy Ci link na Twój adres e-mail, aby zresetować hasło. Sprawdź swoją skrzynkę odbiorczą wkrótce.",
      "Invalid email format.": "Nieprawidłowy format adresu e-mail.",
      "Please enter a valid email, with the format": "Wprowadź prawidłowy adres e-mail, w formacie",
      "No user exists with the provided email.": "Nie istnieje użytkownik z podanym adresem e-mail.",
      "User not found": "Nie znaleziono użytkownika",
    },
    "TR": {
      "Email": "E-posta",
      "Enter your e-mail": "E-postanızı girin",
      "Reset Password": "Şifreyi sıfırla",
      "Email field is empty.": "E-posta alanı boş.",
      "Send Email": "E-posta gönder",
      "Please enter your email address in the field below.": "Lütfen aşağıdaki alana e-posta adresinizi girin.",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "Şifrenizi sıfırlamak için e-postanıza bir bağlantı göndereceğiz. Lütfen gelen kutunuzu kısa süre içinde kontrol edin.",
      "Invalid email format.": "Geçersiz e-posta formatı.",
      "Please enter a valid email, with the format": "Lütfen geçerli bir e-posta adresi girin, format",
      "No user exists with the provided email.": "Sağlanan e-posta ile kullanıcı yok.",
      "User not found": "Kullanıcı bulunamadı",
    },
    "ZH": {
      "Email": "电子邮件",
      "Enter your e-mail": "输入您的电子邮件",
      "Reset Password": "重设密码",
      "Email field is empty.": "电子邮件字段为空。",
      "Send Email": "发送电子邮件",
      "Please enter your email address in the field below.": "请在下面的字段中输入您的电子邮件地址。",
      "We will send a link to your email to reset your password. Please check your inbox shortly.": "我们将向您的电子邮件发送一个链接，以重设您的密码。请稍后检查您的收件箱。",
      "Invalid email format.": "电子邮件格式无效。",
      "Please enter a valid email, with the format": "请输入有效的电子邮件，格式为",
      "No user exists with the provided email.": "提供的电子邮件不存在用户。",
      "User not found": "找不到用户",
    },
  }
</i18n>
