import { orderBy } from "lodash";
import { ref } from 'vue';
import axios from 'axios';


export function useGetLine() {
  // Reactive variable to hold the line data
  const line = ref({
    id: null,
    name: null,
    used_at: null,
    line_manufacturer: null,
    pump_type: null,
    pistols_layout: null,
    air_parameter_name: null,
    air_parameter_units: null,
    total_pistols: [],
    pistol_to_pistol_distance: [],
    pistols_min_velocity: null,
    pistols_max_velocity: null,
    pistols_max_movement_range: null,
    pistol_columns_distances: [],
    pistol_columns_vertical_offsets: [],
    powder_recycling_efficiency_percentage: null,
    pistols_max_acceleration: null,
  });

  const getLine = async (lineId) => {
    try {
      let line_response = await axios.get(`/api/v1/fp/line/${lineId}`);

      line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
      line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
      line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
      line_response.data.pistol_columns_vertical_offsets = JSON.parse(
        line_response.data.pistol_columns_vertical_offsets,
      );

      line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

      line.value = line_response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    line,
    getLine,
  };
}

export function useGetPowders() {
  // Reactive variable to hold the line data
  const powders = ref([]);

  const getPowders = async (lineId) => {
    try {
      if (lineId == undefined || lineId == null || lineId == "") {
        const response = await axios.get("/api/v1/blueprint/powder/");
        powders.value = JSON.parse(JSON.stringify(response.data));
      }
      else {
        const response = await axios.get("/api/v1/blueprint/powdersbyline/" + lineId + "/");
        powders.value = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    powders,
    getPowders,
  };
}

export function useGetCalibratedPowderModels() {
  // Reactive variable to hold the line data
  const calibrated_powdermodels = ref([]);

  const getCalibratedPowderModels = async (lineId) => {
    try {
      const response = await axios.get("/api/v1/fp/powdermodels/lineid/" + lineId);
      calibrated_powdermodels.value = JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  return {
    calibrated_powdermodels,
    getCalibratedPowderModels,
  };
}

export function numericPositiveOnly(event) {
  let key = event.key;
  if (
    [
      "Delete",
      "Backspace",
      "Tab",
      "Enter",
      "ArrowUp",
      "ArrowRight",
      "ArrowDown",
      "ArrowLeft",
      ".",
      "Shift",
      "Control",
      ",",
    ].includes(key) ||
    ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
  ) {
    return;
  }
  if (!/\d/.test(key)) {
    event.preventDefault();
  }
}

export function useGetVisit() {
  const visit = ref({
    id: null,
    benchmarkstage: {},
    healthcheckstage: {},
    line: null,
    thicknessadjustmentstage: {},
    gunsequalizationstage: {},
    reciprocatorstage: {},
    spraypatternstage: {
      selected_powder_model: null,
    },
  });

  const getVisit = async visitId => {
    try {
      const response = await axios.get("/api/v1/blueprint/visit/" + visitId);
      visit.value = response.data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    visit,
    getVisit,
  };
}

export function useGetBenchmark() {
  const benchmark_stage_data = ref({
    minimum_target_thickness: null,
    maximum_target_thickness: null,
    reciprocator_period: null,
    guns_movement_range: null,
    line_speed: null,
    coated_height: null,
    nozzle_angle: null,
    gun_settings: null,
    thickness_measurements: Array(5).fill(null),
    powder: null,
  });

  const getBenchmarkData = async (visitId, totalPistols) => {
    try {
      let response = await axios.get(`/api/v1/blueprint/benchmarkstage/` + visitId);
      if (response.data !== null) {
        benchmark_stage_data.value.minimum_target_thickness = response.data.minimum_target_thickness;
        benchmark_stage_data.value.maximum_target_thickness = response.data.maximum_target_thickness;
        benchmark_stage_data.value.reciprocator_period = response.data.reciprocator_period;
        benchmark_stage_data.value.guns_movement_range = response.data.guns_movement_range;
        benchmark_stage_data.value.line_speed = response.data.line_speed;
        benchmark_stage_data.value.coated_height = response.data.coated_height;
        benchmark_stage_data.value.nozzle_angle = response.data.nozzle_angle;
        benchmark_stage_data.value.thickness_measurements = response.data.thickness_measurements;
        benchmark_stage_data.value.powder = response.data.powder;

        if (benchmark_stage_data.value.thickness_measurements === null || benchmark_stage_data.value.thickness_measurements === "") {
          benchmark_stage_data.value.thickness_measurements = Array(5).fill(null);
        } else if (benchmark_stage_data.value.thickness_measurements.length < 5) {
          benchmark_stage_data.value.thickness_measurements = benchmark_stage_data.value.thickness_measurements.concat(
            Array(5 - benchmark_stage_data.value.thickness_measurements.length).fill(null),
          );
        }
        if (response.data.gun_settings === null || response.data.gun_settings.length < 2) {
          benchmark_stage_data.value.gun_settings = Array.from({ length: totalPistols }, () => ({
            air: null,
            amps: null,
            powder: null,
            voltage: null,
            distance: null,
          }));
        } else {
          benchmark_stage_data.value.gun_settings = response.data.gun_settings;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    benchmark_stage_data,
    getBenchmarkData,
  };
}

export function getThicknessMeasurementsMean(thickness_measurements) {
  if (!Array.isArray(thickness_measurements) || thickness_measurements.length === 0) {
    return 0;
  }

  let filtered_thickness_measurements = thickness_measurements.filter(m => m !== null && m !== "");

  if (filtered_thickness_measurements.length === 0) {
    return 0;
  }

  let mean = parseFloat(
    (filtered_thickness_measurements.reduce((a, b) => a + b, 0) / filtered_thickness_measurements.length).toFixed(1),
  );
  return mean;
}

export function getThicknessMeasurementsStd(thickness_measurements) {
  const mean = getThicknessMeasurementsMean(thickness_measurements);

  let filtered_thickness_measurements = thickness_measurements.filter(m => m !== null && m !== "");

  return parseFloat(
    Math.sqrt(
      filtered_thickness_measurements.reduce((sq, n) => sq + Math.pow(n - mean, 2), 0) /
        (filtered_thickness_measurements.length - 1),
    ).toFixed(1),
  );
}

export function measurementsMin(thickness_measurements) {
  return parseFloat(Math.min(...thickness_measurements.filter(m => m !== null && m !== "")).toFixed(1));
}

export function measurementsMax(thickness_measurements) {
  if (!Array.isArray(thickness_measurements) || thickness_measurements.length === 0) {
    return 0;
  }
  return parseFloat(Math.max(...thickness_measurements.filter(m => m !== null && m !== "")).toFixed(1));
}

export function showStatistics(measurements) {
  if (measurements === null || measurements === undefined) {
    return false;
  }

  if (measurements.every(m => m === null || m === "")) {
    return false;
  }

  return getThicknessMeasurementsMean(measurements) > 0 && getThicknessMeasurementsStd(measurements) > 0;
}

export function getStdDifferencePercentage(measurement, benchmarkMeasurement) {
  if (measurement === null || benchmarkMeasurement === null) {
    return null;
  }
  if (measurement.length === 0 || benchmarkMeasurement.length === 0) {
    return null;
  }
  if (measurement.every(m => m === null || m === "") || benchmarkMeasurement.every(m => m === null || m === "")) {
    return null
  }

  const measurementStd = getThicknessMeasurementsStd(measurement);
  const benchmarkStd = getThicknessMeasurementsStd(benchmarkMeasurement);
  if ( benchmarkStd < measurementStd) {
    return null;
  }
  const percent = (((measurementStd - benchmarkStd) / benchmarkStd) * 100).toFixed(1);
  return percent;
}

export function numericOnly(event) {
  let key = event.key;
  if (
    [
      "Delete",
      "Backspace",
      "Tab",
      "Enter",
      "ArrowUp",
      "ArrowRight",
      "ArrowDown",
      "ArrowLeft",
      ".",
      "Shift",
      "Control",
      ",",
      "-",
    ].includes(key) ||
    ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
  ) {
    return;
  }
  if (!/\d/.test(key)) {
    event.preventDefault();
  }
}

export function generateMeasurementList(powderoutput_measurements) {
  const results = [];

  powderoutput_measurements.forEach(gun => {
    const validPairs = gun.gun_measurements.filter(pair => {
      return pair.setting !== "" && pair.weight !== "";
    });

    const emptyBagWeight = gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 0 : gun.empty_bag_weight;

    const result = {
      powderAmount: validPairs.map(pair => parseFloat(pair.setting)),
      emptyBagWeight: emptyBagWeight,
      fullBagWeight: validPairs.map(pair => parseFloat(pair.weight)),
    };

    if (result.powderAmount.length > 0 || result.fullBagWeight.length > 0) {
      results.push(result);
    }
  });

  return results;
}

export function OrderByName(array) {
  return orderBy(array, [array => array.name.toLowerCase()], ["asc"]);
}

export function OrderByDateUpdated(array) {
  return orderBy(array, ["updated_at", "created_at"], ["desc"]);
}

export function OrderByLineUsedDate(array) {
  return orderBy(array, ["used_at", "created_at"], ["desc"]);
}

export function OrderByDateCreated(array) {
  return orderBy(array, ["created_at"], ["desc"]);
}

export function validateEmail(email) {
  const regex = /^\S+@\S+\.\S+$/;
  return regex.test(email);
}

export function gunNamesExist(gun_names){
  if ( gun_names == null
      || gun_names == undefined
      || gun_names.length == 0
      || gun_names.every(name => name === "" || name === null)
  ) {
    return false;
  }
  return true;
}
